<template>
    <span v-if="$route.name === 'createorganization'">
        <CreateOrganization />
    </span>
    <span v-if="$route.name === 'createcommunity'">
        <CreateCommunity />
    </span>
    <span v-if="$route.name === 'createbibleplan'">
        <CreateBiblePlan />
    </span>
    <span v-if="$route.name === 'createarticle'">
        <CreateArticle />
    </span>
    <span v-if="$route.name === 'topic'">
        <Topic />
    </span>
    <span v-if="$route.name === 'createreflectioncommunity'">
        <CreateReflectionCommunity />
    </span>
    <span v-if="$route.name === 'createreflection'">
        <CreateReflection />
    </span>
    <span v-if="$route.name === 'createbibleplancommunity'">
        <CreateBiblePlanCommunity />
    </span>
    <span v-if="$route.name === 'createarticlecommunity'">
        <CreateArticleCommunity />
    </span>
    <span v-if="$route.name === 'createcommunityorganization'">
        <CreateCommunityOrganization />
    </span>
</template>

<script>
import CreateOrganization from '@/components/parent/CreateAgenciesSubParentView.vue'
import CreateCommunity from '@/components/community/CreateCommunityView.vue'
import CreateArticle from '@/components/article/CreateArticleView.vue'
import Topic from '@/components/topic/TopicView.vue'
import CreateReflectionCommunity from '@/components/community/CreateReflectionCommunityView.vue'
import CreateReflection from '@/components/parent/CreateReflectionView.vue'
import CreateBiblePlan from '@/components/parent/CreateBiblePlanView.vue'
import CreateBiblePlanCommunity from '@/components/community/CreateBiblePlanCommunityView.vue'
import CreateArticleCommunity from '@/components/community/CreateArticleCommunityView.vue'
import CreateCommunityOrganization from '@/components/subparent/CreateCommunityOrganizationView.vue'

export default {
    components: {
        CreateOrganization,
        CreateCommunity,
        CreateBiblePlan,
        CreateArticle,
        Topic,
        CreateReflectionCommunity,
        CreateReflection,
        CreateBiblePlanCommunity,
        CreateArticleCommunity,
        CreateCommunityOrganization
    }
}
</script>