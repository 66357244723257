<template>
    <span v-if="$route.name === 'home'">
        <HomeParent />
    </span>
    <span v-if="$route.name === 'homeorganization'">
        <HomeOrganization />
    </span>
    <span v-if="$route.name === 'homecommunity'">
        <HomeCommunity />
    </span>
</template>

<script>
import HomeParent from '@/components/parent/HomeParentView.vue'
import HomeOrganization from '@/components/subparent/HomeOrganizationView.vue'
import HomeCommunity from '@/components/community/HomeCommunityView.vue'

export default {
    components: {
        HomeParent,
        HomeOrganization,
        HomeCommunity
    }
}
</script>