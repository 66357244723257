<template>
    <v-container fluid>
        <v-dialog v-model="isLoading" persistent width="auto">
            <div class="text-center">
                <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
            </div>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card style="background-color: #50b454; color: white;" title="TOPIC" elevation="10"></v-card>
                <br>
                <v-spacer></v-spacer>
                <br>
                <v-card>
                    <v-col>
                        <v-form @submit.prevent="submitForm">
                            <v-text-field v-model="topicModel" :rules="topicRules" color="primary" label="Create Topic"
                                variant="outlined"></v-text-field>
                            <v-autocomplete v-model="languageModel" :items="language" item-value="alpha2"
                                item-title="english" :rules="languageRules" label="Select Language"
                                variant="outlined"></v-autocomplete>
                            <v-btn type="submit" color="primary" class="mt-4" block>SUBMIT</v-btn>
                        </v-form>
                    </v-col>
                </v-card>
                <br>
                <h1>LIST TOPIC</h1>
                <hr>
                <br>
                <v-text-field v-model="search" append-inner-icon="mdi-magnify" label="Search" single-line hide-details
                    variant="solo"></v-text-field>
                <br>
                <v-card elevation="10">
                    <v-data-table :headers="headers" :items="list" :search="search" align="center" outlined>
                        <template v-slot:top>
                            <v-toolbar flat>

                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon size="small" @click="openEditDialog(item.raw)" class="me-2">
                                mdi-pencil
                            </v-icon>
                            <v-dialog v-model="dialog" persistent width="1024">
                                <v-card>
                                    <v-card-title>
                                        <span class="text-h5">Edit Topic</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col>
                                                    <v-text-field v-model="topicUpdateModel" :rules="topicUpdateRules"
                                                        label="Topic" required></v-text-field>
                                                </v-col>
                                                <v-col>
                                                    <v-autocomplete v-model="languageModel2" :items="language2"
                                                        item-value="alpha2" item-title="english" :rules="languageRules"
                                                        label="Select Language" variant="outlined"></v-autocomplete>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
                                            Close
                                        </v-btn>
                                        <v-btn color="blue-darken-1" variant="text" @click="editItem(item.raw)">
                                            Update
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import { VDataTable } from 'vuetify/labs/VDataTable'

export default {
    mounted() {
        this.getLanguage()
        this.getList()
    },
    components: {
        VDataTable,
    },
    data() {
        return {
            isLoading: false,
            language: [],
            language2: [],
            language3: [],
            languageModel: '',
            languageModel2: '',
            languageModel3: '',
            dialog: false,
            search: '',
            topicModel: "",
            topicUpdate: "",
            topicId: "",
            headers: [
                {
                    align: 'start',
                    value: 'id',
                    sortable: true,
                    title: 'ID',
                },
                {
                    align: 'start',
                    value: 'topic',
                    sortable: true,
                    title: 'TOPIC',
                },
                {
                    align: 'start',
                    value: 'language',
                    sortable: true,
                    title: 'LANGUAGE',
                },
                { title: "ACTIONS", key: "actions", sortable: false },
            ],
            list: [],
            topicRules: [(v) => !!v || "Topic is required"],
            topicUpdateRules: [(v) => !!v || "Topic is required"],
            languageRules: [
                v => !!v || 'Language is required',
            ],
        }
    },
    methods: {
        openEditDialog(item) {
            this.getLanguage2()
            this.dialog = true
            this.topicId = item.id
            this.topicUpdateModel = item.topic
            this.languageModel2 = item.language
        },
        editItem() {
            this.dialog = false
            this.$swal
                .fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Update",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        var formData = new FormData();
                        formData.append("topicID", this.topicId);
                        formData.append("topic", this.topicUpdateModel);
                        formData.append("language", this.languageModel2);

                        axios
                            .post(
                                this.$store.state.API_PATH_TOPIC + "update-topic",
                                formData,
                                {
                                    headers: {
                                        "Content-Type": "multipart/form-data",
                                    },
                                }
                            )
                            .then((response) => {
                                this.$swal
                                    .fire({
                                        title: "success",
                                        text: response.data.message,
                                        type: "success",
                                        icon: "success",
                                    })
                                    .then(function () {
                                        location.reload();
                                    });
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                            .finally(() => (this.isLoading = false));
                    }
                });
        },
        async getLanguage() {
            await axios.get(this.$store.state.API_PATH_AGENCIES + 'get-language').then((response) => {
                this.language = response.data.data
            }).catch(error => {
                console.log(error)
            })
        },
        async getLanguage2() {
            this.isLoading = true
            await axios.get(this.$store.state.API_PATH_AGENCIES + 'get-language').then((response) => {
                this.language2 = response.data.data
            }).catch(error => {
                console.log(error)
            }).finally(() => (this.isLoading = false))
        },
        async submitForm(event) {
            const result = await event;
            const parse = JSON.parse(JSON.stringify(result));
            if (parse.valid) {
                this.$swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Create",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true;
                            var formData = new FormData();
                            formData.append("topic", this.topicModel);
                            formData.append("language", this.languageModel);

                            axios
                                .post(
                                    this.$store.state.API_PATH_TOPIC + "create-topic",
                                    formData,
                                    {
                                        headers: {
                                            "Content-Type": "multipart/form-data",
                                        },
                                    }
                                )
                                .then((response) => {
                                    this.$swal
                                        .fire({
                                            title: "success",
                                            text: response.data.message,
                                            type: "success",
                                            icon: "success",
                                        })
                                        .then(function () {
                                            location.reload();
                                        });
                                })
                                .catch((error) => {
                                    console.log(error);
                                })
                                .finally(() => (this.isLoading = false));
                        }
                        else {
                            this.$swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Check Required Input!",
                            });
                        }
                    });
            }
        },
        async getList() {
            await axios.get(this.$store.state.API_PATH_TOPIC + 'list-all-topic', null).then((response) => {
                this.list = response.data.data
            }).catch(error => {
                console.log(error)
            })
        }
    }
}
</script>