<template>
  <div class="login">
    <Login />
  </div>
</template>

<script>
import Login from '@/components/Login.vue'

export default {
  components: {
    Login
  }
}
</script>
