<template>
    <span v-if="$route.name === 'editorganization'">
        <EditOrganization />
    </span>
    <span v-if="$route.name === 'editcommunity'">
        <EditCommunity />
    </span>
    <span v-if="$route.name === 'editarticle'">
        <EditArticle />
    </span>
    <span v-if="$route.name === 'editparent'">
        <EditParent />
    </span>
    <span v-if="$route.name === 'editreflectioncommunity'">
        <EditReflectionCommunity />
    </span>
    <span v-if="$route.name === 'editbibleplan'">
        <EditBiblePlan />
    </span>
    <span v-if="$route.name === 'editreflection'">
        <EditReflection />
    </span>
    <span v-if="$route.name === 'editbibleplancommunity'">
        <EditBiblePlanCommunity />
    </span>
    <span v-if="$route.name === 'editarticlecommunity'">
        <EditArticleCommunity />
    </span>
</template>

<script>
import EditOrganization from '@/components/parent/EditAgenciesSubParentView.vue'
import EditCommunity from '@/components/community/EditCommunityView.vue'
import EditArticle from '@/components/article/EditArticleView.vue'
import EditParent from '@/components/parent/EditParentView.vue'
import EditReflectionCommunity from '@/components/community/EditReflectionCommunityView.vue'
import EditBiblePlan from '@/components/parent/EditBiblePlanView.vue'
import EditReflection from '@/components/parent/EditReflectionView.vue'
import EditBiblePlanCommunity from '@/components/community/EditBiblePlanCommunityView.vue'
import EditArticleCommunity from '@/components/community/EditArticleCommunityView.vue'

export default {
    components: {
        EditOrganization,
        EditCommunity,
        EditArticle,
        EditParent,
        EditReflectionCommunity,
        EditBiblePlan,
        EditReflection,
        EditBiblePlanCommunity,
        EditArticleCommunity,
    }
}
</script>