<template>
  <v-container fluid>
    <v-dialog v-model="isLoading" persistent width="auto">
      <div class="text-center">
        <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
      </div>
    </v-dialog>
    <v-row>
      <v-col>
        <v-card style="background-color: #50b454; color: white;" title="COMMUNITY LIST" elevation="10"></v-card>
        <br>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-inner-icon="mdi-magnify" label="Search" single-line hide-details
          variant="solo"></v-text-field>
        <br>
        <v-card elevation="10">
          <v-data-table :headers="headers" :items="itemsWithStatusStrings" :search="search" align="center" outlined>
            <template v-slot:top>
              <v-toolbar flat>
                <v-divider class="mx-4" inset vertical></v-divider>
                <div style="background-color: red;" class="text-left">
                  <v-btn @click="trashItem" type="submit" color="white" elevation="2"><v-icon>
                      mdi-delete </v-icon></v-btn>
                </div>
                <v-spacer></v-spacer>
                <v-dialog>
                  <template v-slot:activator="{ props }">
                    <v-btn color="primary" dark class="mb-2" @click="createItem()" v-bind="props">
                      <v-icon icon="mdi-plus"></v-icon> CREATE
                    </v-btn>
                  </template>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.createbibleplan`]="{ item }">
              <v-btn color="success" @click="listBiblePlan(item.raw)">
                LIST
              </v-btn>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon size="small" class="me-2" @click="editItem(item.raw)">
                mdi-pencil
              </v-icon>
              <v-icon size="small" class="me-2" @click="setStatusActive(item.raw)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { VDataTable } from 'vuetify/labs/VDataTable'

export default {
  mounted() {
    this.getList()
  },
  components: {
    VDataTable,
  },
  data() {
    return {
      isLoading: false,
      search: '',
      headers: [
        {
          align: 'start',
          value: 'communityName',
          sortable: true,
          title: 'COMMUNITY NAME',
        },
        {
          align: 'start',
          value: 'communityId',
          sortable: true,
          title: 'COMMUNITY ID',
        },
        {
          align: 'start',
          value: 'statusActive',
          sortable: false,
          title: 'STATUS ACTIVE',
        },
        { title: "BIBLE PLAN", key: "createbibleplan", sortable: false },
        { title: "ACTIONS", key: "actions", sortable: false },
      ],
      list: [],
    }
  },
  methods: {
    listBiblePlan(item) {
      this.$swal.fire({
        icon: "error",
        title: "Oops...",
        text: "This Page under Maintenance!",
      })
      console.log(item)
      // this.$router.push({ name: 'listtrashcommunity', params: { id: item.id } })
    },
    createItem() {
      this.$router.push({ name: 'createcommunity' })
    },
    editItem(item) {
      this.$router.push({ name: 'editcommunity', params: { id: item.id } })
    },
    trashItem() {
      this.$router.push({ name: 'listtrashcommunity', params: { id: this.$route.params.id } })
    },
    setStatusActive(item) {
      var formData = new FormData()
      formData.append('id', item.id)
      formData.append('statusActive', false)
      this.$swal
        .fire({
          title: "Are you sure?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, remove",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true
            axios.post(this.$store.state.API_PATH_COMMUNITY + 'set-statusactive-community', formData).then((response) => {
              this.$swal
                .fire({
                  title: "success",
                  text: response.data.message,
                  type: "success",
                  icon: "success",
                })
                .then(function () {
                  location.reload();
                });
            }).catch(error => {
              console.log(error)
            }).finally(() => (this.isLoading = false))
          }
        })
    },
    async getList() {
      this.isLoading = true
      let agenciesId = this.$route.params.id
      await axios.get(this.$store.state.API_PATH_COMMUNITY + 'get-by-agencies-id', { params: { agenciesId: agenciesId, statusActive: true } }).then((response) => {
        this.list = response.data.data
      }).catch(error => {
        console.log(error)
      }).finally(() => (this.isLoading = false))
    },
  },
  computed: {
    itemsWithStatusStrings() {
      return this.list.map(item => {
        return {
          ...item,
          statusActive: item.statusActive ? 'Active' : 'Inactive'
        };
      });
    }
  }
}
</script>
