<template>
    <span v-if="$route.name === 'listorganization'">
        <ListOrganization />
    </span>
    <span v-if="$route.name === 'listcommunity'">
        <ListCommunity />
    </span>
    <span v-if="$route.name === 'membercommunity'">
        <MemberCommunity />
    </span>
    <span v-if="$route.name === 'bibleplan'">
        <ListBiblePlan />
    </span>
    <span v-if="$route.name === 'listarticle'">
        <ListArticle />
    </span>
    <span v-if="$route.name === 'listusers'">
        <ListUsers />
    </span>
    <span v-if="$route.name === 'reflectioncommunity'">
        <ListReflectionCommunity />
    </span>
    <span v-if="$route.name === 'reflection'">
        <ListReflection />
    </span>
    <span v-if="$route.name === 'bibleplancommunity'">
        <ListBiblePlanCommunity />
    </span>
    <span v-if="$route.name === 'articlecommunity'">
        <ListArticleCommunity />
    </span>
    <span v-if="$route.name === 'listcommunityorganization'">
        <ListCommunityOrganization />
    </span>
</template>


<script>
import ListOrganization from '@/components/parent/ListAgenciesSubParentView.vue'
import ListCommunity from '@/components/community/ListCommunityView.vue'
import MemberCommunity from '@/components/community/MemberCommunityView.vue'
import ListArticle from '@/components/article/ListArticleView.vue'
import ListUsers from '@/components/parent/ListUsersParentView.vue'
import ListReflectionCommunity from '@/components/community/ListReflectionCommunityView.vue'
import ListReflection from '@/components/parent/ListReflectionView.vue'
import ListBiblePlan from '@/components/parent/ListBiblePlanView.vue'
import ListBiblePlanCommunity from '@/components/community/ListBiblePlanCommunityView.vue'
import ListArticleCommunity from '@/components/community/ListArticleCommunityView.vue'
import ListCommunityOrganization from '@/components/subparent/ListCommunityOrganizationView.vue'

export default {
    components: {
        ListOrganization,
        ListCommunity,
        MemberCommunity,
        ListBiblePlan,
        ListArticle,
        ListUsers,
        ListReflectionCommunity,
        ListReflection,
        ListBiblePlanCommunity,
        ListArticleCommunity,
        ListCommunityOrganization
    }
}
</script>