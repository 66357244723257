<template>
  <v-container fluid>
    <v-dialog v-model="isLoading" persistent width="auto">
      <div class="text-center">
        <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
      </div>
    </v-dialog>
    <v-row>
      <v-col>
        <v-card elevation="10">
          <span v-if="showMessage">
            <div class="rounded-top" style="background-color: red; width: 100%; height: auto; color: white;">
              Error Message!!!</div>
          </span>
          <img src="@/assets/identity.png" alt="identity" style="max-width:100%;">
          <div class="flex-center">
            <label>Select Type Of Sign In :</label>
            <v-radio-group v-model="user.loginType" color="success" inline>
              <v-radio label="admin" value="role_1"></v-radio>
              <v-radio label="organization" value="role_2"></v-radio>
              <v-radio label="community" value="community"></v-radio>
            </v-radio-group>
          </div>
          <hr>
          <br>
          <label>Sign In With:</label>
          <v-card-text>
            <GoogleLogin :callback="callback" />
          </v-card-text>
          <label>Or:</label>
          <v-card-text>
            <v-form>
              <v-text-field label="Email Address" v-model="user.email" prepend-icon="mdi-account-circle"
                variant="outlined"></v-text-field>
              <v-text-field label="Password" v-model="user.password" :type="showPassword ? 'text' : 'password'"
                prepend-icon="mdi-lock" append-inner-icon="mdi-eye-off" @click:append-inner="handleclick"
                variant="outlined"></v-text-field>
              <v-btn block color="success" elevation="2" @click.prevent="login(user)">SIGN IN</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

export default ({
  data() {
    return {
      isLoading: false,
      dialog: false,
      showMessage: false,
      showPassword: false,
      existByPicCommunity: false,
      existByPicAgencies: false,
      user: {
        email: '',
        password: '',
        loginType: ''
      },
    }
  },
  methods: {
    handleclick() {
      this.showPassword = !this.showPassword
    },
    decodeJwtResponse(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    async getUserByEmail(email, role, token) {
      this.isLoading = true
      var thisTemp = this
      await axios.get(this.$store.state.API_PATH_USERS + 'check-login-user',
        {
          params: { role: role, email: email }
        }).then((response) => {
          if (this.user.loginType === "") {
            thisTemp.$swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Choose Type Of Sign In!",
            })
          } else {
            if (role === 'role_1' && response.data.data.login) {
              sessionStorage.setItem("credentials", JSON.stringify(response.data.data.users))
              sessionStorage.setItem("role", role)
              sessionStorage.setItem("token", token)
              thisTemp.$router.push({ name: 'home' })
            } else if (role === 'role_2' && response.data.data.login) {
              sessionStorage.setItem("credentials", JSON.stringify(response.data.data.users))
              sessionStorage.setItem("role", role)
              sessionStorage.setItem("token", token)
              thisTemp.$router.push({ name: 'homeorganization' })
            } else if (role === 'community' && response.data.data.login) {
              sessionStorage.setItem("credentials", JSON.stringify(response.data.data.users))
              sessionStorage.setItem("role", role)
              sessionStorage.setItem("token", token)
              thisTemp.$router.push({ name: 'homecommunity' })
            } else {
              thisTemp.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: response.data.message
              })
            }
          }
        }).catch(error => {
          thisTemp.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "You Are Not Eligible To SignIn",
          })
          console.log(error)
        }).finally(() => (this.isLoading = false))
    },
    callback(response) {
      this.getUserByEmail(this.decodeJwtResponse(response.credential).email, this.user.loginType, response.credential)
    },
    login() {
      var thisTemp = this
      const auth = getAuth()
      signInWithEmailAndPassword(auth, this.user.email, this.user.password)
        .then((data) => {
          this.getUserByEmail(this.decodeJwtResponse(data.user.accessToken).email, this.user.loginType, data.user.accessToken)
        })
        .catch((error) => {
          thisTemp.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Bad Credentials! " + error.message,
          }).then((result) => {
            if (result.isConfirmed) {
              thisTemp.user.email = ""
              thisTemp.user.password = "";
            }
          })
        })
    },
  },
})
</script>

<style scoped>
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>