<template>
    <v-container fluid>
        <v-dialog v-model="isLoading" persistent width="auto">
            <div class="text-center">
                <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
            </div>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card style="background-color: #50b454; color: white;" title="USERS LIST" elevation="10"></v-card>
                <br>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-inner-icon="mdi-magnify" label="Search" single-line hide-details
                    variant="solo"></v-text-field>
                <br>
                <v-card elevation="10">
                    <v-data-table :headers="headers" :items="itemsWithStatusStrings" :search="search" align="center"
                        outlined>
                        <template v-slot:top>
                            <v-toolbar flat>

                            </v-toolbar>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import { VDataTable } from 'vuetify/labs/VDataTable'

export default {
    mounted() {
        this.getList()
    },
    components: {
        VDataTable,
    },
    data() {
        return {
            isLoading: false,
            search: '',
            headers: [
                {
                    align: 'start',
                    value: 'email',
                    sortable: true,
                    title: 'EMAIL',
                },
                {
                    align: 'start',
                    value: 'lastLogin',
                    sortable: true,
                    title: 'LAST LOGIN',
                },
            ],
            list: [],
        }
    },
    methods: {
        async getList() {
            this.isLoading = true
            await axios.get(this.$store.state.API_PATH_USERS + 'get-all-user', null).then((response) => {
                this.list = response.data.data
                console.log(this.list)
            }).catch(error => {
                console.log(error)
            }).finally(() => (this.isLoading = false))
        },
    },
    computed: {
        itemsWithStatusStrings() {
            return this.list.map(item => {
                return {
                    ...item,
                    statusActive: item.statusActive ? 'Active' : 'Inactive'
                };
            });
        }
    }
}
</script>
