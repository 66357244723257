<template>
    <v-container fluid>
        <v-dialog v-model="isLoading" persistent width="auto">
            <div class="text-center">
                <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
            </div>
        </v-dialog>
        <v-dialog v-model="isLoading" persistent width="auto">
            <div class="text-center">
                <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
            </div>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card style="background-color: #50b454; color: white;" title="COMMUNITY LIST" elevation="10"></v-card>
                <br>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-inner-icon="mdi-magnify" label="Search" single-line hide-details
                    variant="solo"></v-text-field>
                <br>
                <v-card elevation="10">
                    <v-data-table :headers="headers" :items="itemsWithStatusStrings" :search="search" align="center"
                        outlined>
                        <template v-slot:[`item.actions`]="{ item }">
                            <span v-if="item.raw.statusActive === 'Active'">
                                <v-icon size="small" class="me-2" @click="reflectionItem(item.raw)">
                                    mdi-book-plus-outline
                                </v-icon>
                                <v-icon size="small" class="me-2" @click="biblePlanItem(item.raw)">
                                    mdi-file-document-edit-outline
                                </v-icon>
                                <v-icon size="small" class="me-2" @click="articleItem(item.raw)">
                                    mdi mdi-book-open
                                </v-icon>
                                <v-icon size="small" class="me-2" @click="memberItem(item.raw)">
                                    mdi-account-group
                                </v-icon>
                            </span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import { VDataTable } from 'vuetify/labs/VDataTable'

export default {
    mounted() {
        this.getList()
    },
    components: {
        VDataTable,
    },
    data() {
        return {
            isLoading: false,
            search: '',
            headers: [
                {
                    align: 'start',
                    value: 'communityName',
                    sortable: true,
                    title: 'COMMUNITY NAME',
                },
                {
                    align: 'start',
                    value: 'communityId',
                    sortable: true,
                    title: 'COMMUNITY ID',
                },
                {
                    align: 'start',
                    value: 'statusActive',
                    sortable: false,
                    title: 'STATUS ACTIVE',
                },
                { title: "ACTIONS", key: "actions", sortable: false },
            ],
            list: [],
        }
    },
    methods: {
        reflectionItem(item) {
            this.$router.push({ name: 'reflectioncommunity', params: { id: item.id } })
        },
        biblePlanItem(item) {
            this.$router.push({ name: 'bibleplancommunity', params: { id: item.id } })
        },
        articleItem(item) {
            this.$router.push({ name: 'articlecommunity', params: { id: item.id } })
        },
        memberItem(item) {
            this.$router.push({ name: 'membercommunity', params: { id: item.id } })
        },
        async getList() {
            this.isLoading = true
            let parse = JSON.parse(sessionStorage.getItem('credentials'))
            let idUser = parse.id
            await axios.get(this.$store.state.API_PATH_COMMUNITY + 'get-communityby-pic', { params: { idUser: idUser } }).then((response) => {
                this.list = response.data.data
            }).catch(error => {
                console.log(error)
            }).finally(() => (this.isLoading = false))
        },
    },
    computed: {
        itemsWithStatusStrings() {
            return this.list.map(item => {
                return {
                    ...item,
                    statusActive: item.statusActive ? 'Active' : 'Inactive'
                };
            });
        }
    }
}
</script>
