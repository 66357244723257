<template>
    <v-container fluid>
        <v-dialog v-model="isLoading" persistent width="auto">
            <div class="text-center">
                <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
            </div>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card style="background-color: #50b454; color: white;" title="CREATE ORGANIZATION"
                    elevation="10"></v-card>
                <br>
                <v-card elevation="10">
                    <v-card-text>
                        <v-form @submit.prevent="submitForm">
                            <v-row>
                                <v-col>
                                    <v-text-field color="primary" label="Name" v-model="nameModel" :rules="nameRules"
                                        variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field @input="checkIdAgencies" color="primary" label="ID" v-model="idModel"
                                        :rules="[requiredIdRule, idExistsRule]" variant="outlined"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field type="email" color="primary" label="email" v-model="emailModel"
                                        :rules="emailRules" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field color="primary" label="phone" v-model="phoneModel" :rules="phoneRules"
                                        variant="outlined" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field color="primary" label="Address" v-model="addressModel"
                                        :rules="addressRules" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field color="primary" label="Postal Code" v-model="postalCodeModel"
                                        :rules="postalCodeRules" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-combobox label="Country" :items="language" item-title="english" item-value="alpha2"
                                        v-model="languageModel" :rules="languageRules" variant="outlined"
                                        required></v-combobox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-combobox :items="pic" item-title="email" item-value="id" chips clearable
                                        label="PIC (Person In Charge)" v-model="picModel" :rules="picRules"
                                        variant="outlined" required>
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                                @click:close="remove(item)">
                                                <strong>{{ item.email }}</strong>&nbsp;
                                                <span>(Email)</span>
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col>
                                    <v-combobox v-model="roleModel" :rules="roleRules" :items="role" item-title="role"
                                        item-value="id" label="Role" variant="outlined" required></v-combobox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-file-input v-model="imageModel" @change="onFileSelectedImage"
                                        @click:clear="clearFileImage" :rules="imageRules" prepend-icon="mdi-camera"
                                        label="Upload Image" accept="image/png, image/jpeg, image/bmp" :show-size="1000"
                                        variant="outlined" required></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row v-if="imageUrl">
                                <v-col>
                                    <img style="max-width: 50%;" :src="imageUrl" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-file-input @change="onFileSelectedDocument" @click:clear="clearFileDocument"
                                        v-model="documentModel" :rules="documentRules" prepend-icon="mdi-file-upload"
                                        label="Upload MOU Document" accept="application/pdf" :show-size="1000"
                                        variant="outlined" required></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row v-if="pdfUrl">
                                <v-col>
                                    <iframe width="80%" height="500" frameborder="0" scrolling="no" :src="pdfUrl"></iframe>
                                </v-col>
                            </v-row>
                            <v-btn type="submit" color="success" class="mt-4" block>SUBMIT</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'

export default {
    mounted() {
        this.getRoleAgencies()
        this.getLanguage()
        this.getAllUser()
    },
    data() {
        return {
            isLoading: false,
            idExists: false,
            imageUrl: null,
            pdfUrl: null,
            language: [],
            pic: [],
            role: [],
            nameModel: '',
            idModel: '',
            emailModel: '',
            phoneModel: '',
            addressModel: '',
            postalCodeModel: '',
            languageModel: '',
            picModel: '',
            roleModel: '',
            imageModel: null,
            documentModel: null,
            nameRules: [
                v => !!v || 'Name is required',
            ],
            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+/.test(v) || 'Email must be valid',
            ],
            phoneRules: [
                v => !!v || 'Phone is required',
            ],
            addressRules: [
                v => !!v || 'Address is required',
            ],
            postalCodeRules: [
                v => !!v || 'Postal Code is required',
            ],
            languageRules: [
                v => !!v || 'Language Code is required',
            ],
            picRules: [
                v => !!v || 'PIC is required',
            ],
            roleRules: [
                v => !!v || 'Role is required',
            ],
            imageRules: [
                (value) => !!value || 'Image is required',
                (value) => !value || !value.some(file => file.size > 2e6) || 'Image size must be less than 2 MB'
            ],
            documentRules: [
                (value) => !!value || 'Document MOU is required',
                (value) => !value || !value.some(file => file.size > 1e6) || 'Document size must be less than 1 MB'
            ],
        };
    },
    methods: {
        remove(item) {
            this.chips.splice(this.chips.indexOf(item), 1)
        },
        onFileSelectedImage(event) {
            const file = event.target.files[0]
            this.selectedFile = file
            this.imageUrl = file ? URL.createObjectURL(file) : null
        },
        onFileSelectedDocument(event) {
            const file = event.target.files[0]
            this.selectedFile = file
            this.pdfUrl = file ? URL.createObjectURL(file) : null
        },
        clearFileImage() {
            this.imageModel = null
            this.imageUrl = null
        },
        clearFileDocument() {
            this.documentModel = null
            this.pdfUrl = null
        },
        async checkIdAgencies() {
            await axios.get(this.$store.state.API_PATH_AGENCIES + 'check-idagencies', { params: { type: 1, idAgencies: this.idModel } }).then((response) => {
                this.idExists = response.data.data
            }).catch(error => {
                console.log(error)
            })
        },
        async getAllUser() {
            await axios.get(this.$store.state.API_PATH_USERS + 'get-all-user').then((response) => {
                this.pic = response.data.data
            }).catch(error => {
                console.log(error)
            })
        },
        async getRoleAgencies() {
            await axios.get(this.$store.state.API_PATH_AGENCIES + 'get-roleagencies').then((response) => {
                this.role.push(response.data.data[1])
            }).catch(error => {
                console.log(error)
            })
        },
        async getLanguage() {
            await axios.get(this.$store.state.API_PATH_AGENCIES + 'get-language').then((response) => {
                this.language = response.data.data
            }).catch(error => {
                console.log(error)
            })
        },
        async submitForm(event) {
            const result = await event
            const parse = JSON.parse(JSON.stringify(result))

            if (parse.valid) {
                this.$swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Create",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true
                            var formData = new FormData();
                            formData.append('idAgencies', this.idModel)
                            formData.append('name', this.nameModel)
                            formData.append('email', this.emailModel)
                            formData.append('phone', this.phoneModel)
                            formData.append('address', this.addressModel)
                            formData.append('code', this.languageModel.alpha2)
                            formData.append('postalCode', this.postalCodeModel)
                            formData.append('images', this.imageModel[0])
                            formData.append('documents', this.documentModel[0])
                            formData.append('pic', this.picModel.id)
                            formData.append('role', this.roleModel.idRole)

                            axios.post(this.$store.state.API_PATH_AGENCIES + 'create-agencies', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            }).then((response) => {
                                this.$swal
                                    .fire({
                                        title: "success",
                                        text: response.data.message,
                                        type: "success",
                                        icon: "success",
                                    })
                                    .then(function () {
                                        // location.reload();
                                        this.$router.push({ name: 'listorganization' })
                                    });
                            }).catch(error => {
                                console.log(error)
                            }).finally(() => (this.isLoading = false))
                        }
                    })
            } else {
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Check Required Input!",
                })
            }
        }
    },
    computed: {
        requiredIdRule() {
            return (value) => !!value || 'ID is required';
        },
        idExistsRule() {
            return () => !this.idExists || 'ID already exists'
        }
    }
};
</script>

<style scoped>
.v-progress-circular {
    margin: 1rem;
}
</style>