<template>
    <v-container fluid>
        <v-dialog v-model="isLoading" persistent width="auto">
            <div class="text-center">
                <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
            </div>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card style="background-color: #50b454; color: white;" title="MEMBER COMMUNITY" elevation="10"></v-card>
                <br>
                <v-card elevation="10">
                    <v-card-text>
                        <v-form>
                            <v-row>
                                <v-col>
                                    <v-combobox :items="member" item-title="email" item-value="id" chips clearable
                                        label="Add Member" v-model="memberModel" variant="outlined" required>
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                                @click:close="remove(item)">
                                                <strong>{{ item.email }}</strong>&nbsp;
                                                <span>(Email)</span>
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="2">
                                    <v-select label="Select Member Type" v-model="memberType" :items="['pic', 'member']"
                                        variant="outlined"></v-select>
                                </v-col>
                                <v-col cols="1" style="margin-top: -0.9%">
                                    <v-btn justify="center" @click="addMember" color="primary" class="mt-4" text icon>
                                        <v-icon>mdi-plus</v-icon> </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="searchMember" append-inner-icon="mdi-magnify"
                                        label="Search Member" single-line hide-details variant="solo"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-data-table :headers="headers" :items="listMember" :search="searchMember"
                                        :sort-by="[{ key: 'registered', order: 'desc' }]" align="center" outlined>
                                        <template v-slot:top>
                                            <v-toolbar flat>
                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                <div class="text-left">
                                                    <h3>MEMBER LIST</h3>
                                                </div>
                                            </v-toolbar>
                                        </template>
                                        <template v-slot:[`item.changeMember`]="{ item }">
                                            <span v-if="!item.props.title.email.includes(filterEmail)">
                                                <span v-if="item.props.title.memberType.includes('pic')">
                                                    <v-btn color="primary" style="color: white" elevation="2" small
                                                        @click="changeMemberType(item.raw)">CHANGE TO MEMBER</v-btn>
                                                </span>
                                                <span v-if="item.props.title.memberType.includes('member')">
                                                    <v-btn color="red" style="color: white" elevation="2" small
                                                        @click="changeMemberType(item.raw)">CHANGE TO PIC</v-btn>
                                                </span>
                                            </span>
                                        </template>
                                        <template v-slot:[`item.registered`]="{ item }">
                                            <span v-if="!item.props.title.email.includes(filterEmail)">
                                                <span v-if="item.props.title.registered.includes('P')">
                                                    <v-btn color="red" style="color: white" elevation="2" small
                                                        @click="approveMember(item.raw)">CONFIRM</v-btn>
                                                </span>
                                            </span>
                                        </template>
                                        <template v-slot:[`item.actions`]="{ item }">
                                            <v-icon v-if="!item.props.title.email.includes(filterEmail)" size="small"
                                                class="me-2" @click="deleteItem(item.raw)">
                                                mdi-delete
                                            </v-icon>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { VDataTable } from 'vuetify/labs/VDataTable'
import axios from 'axios'


export default {
    components: {
        VDataTable,
    },
    mounted() {
        this.getDataById()
    },
    data() {
        return {
            filterEmail: '',
            memberType: 'pic',
            searchMember: '',
            isLoading: false,
            member: [],
            memberModel: '',
            headers: [
                {
                    title: 'EMAIL',
                    sortable: true,
                    key: 'email',
                },
                {
                    title: 'USERNAME',
                    sortable: true,
                    key: 'username',
                },
                {
                    title: 'APPROVED',
                    sortable: true,
                    key: 'registered',
                },
                {
                    title: 'TYPE',
                    sortable: true,
                    key: 'memberType',
                },
                { title: "CHANGE MEMBER TYPE", key: 'changeMember', sortable: false },
                { title: "ACTIONS", key: 'actions', sortable: false },
            ],
            listMember: [],
        };
    },
    methods: {
        addMember() {
            if (this.memberModel === "") {
                this.$swal
                    .fire({
                        title: "warning",
                        text: "Can't add, because text field empty",
                        type: "warning",
                        icon: "warning",
                    })
            } else {
                var type
                if (this.memberType === 'pic') {
                    type = 1
                } else {
                    type = 2
                }
                this.$swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Add",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true
                            let id = this.$route.params.id
                            var formData = new FormData();
                            formData.append('id', id)
                            formData.append('member', this.memberModel.id)
                            formData.append('type', type)


                            axios.post(this.$store.state.API_PATH_COMMUNITY + 'add-member', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            }).then((response) => {
                                this.$swal
                                    .fire({
                                        title: "success",
                                        text: response.data.message,
                                        type: "success",
                                        icon: "success",
                                    })
                                    .then(function () {
                                        location.reload();
                                    });
                            }).catch(error => {
                                console.log(error)
                            }).finally(() => (this.isLoading = false))
                        }
                    })
            }

        },
        deleteItem(item) {
            if (this.listMember.filter(member => member.memberType === 'pic').length === 1 && item.memberType === 'pic') {
                this.$swal
                    .fire({
                        title: "warning",
                        text: "Can't remove, because there's only one pic",
                        type: "warning",
                        icon: "warning",
                    })
            } else {
                this.$swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, remove",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true
                            let id = this.$route.params.id
                            axios.delete(this.$store.state.API_PATH_COMMUNITY + 'remove-member', { params: { id: id, member: item.id } }, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            }).then((response) => {
                                this.$swal
                                    .fire({
                                        title: "success",
                                        text: response.data.message,
                                        type: "success",
                                        icon: "success",
                                    })
                                    .then(function () {
                                        location.reload();
                                    });
                            }).catch(error => {
                                console.log(error)
                            }).finally(() => (this.isLoading = false))
                        }
                    })
            }
        },
        async getAllUser() {
            axios.get(this.$store.state.API_PATH_USERS + 'get-all-user').then((response) => {
                this.member = response.data.data.filter(member => !this.listMember.some(listMember => listMember.email === member.email))
            }).catch(error => {
                console.log(error)
            })
        },
        async getDataById() {
            this.isLoading = true
            let id = this.$route.params.id
            await axios.get(this.$store.state.API_PATH_COMMUNITY + 'get-detail-community', { params: { id: id } }).then((response) => {
                this.listMember = response.data.data.member
                this.center = [parseFloat(response.data.data.location.latitude), parseFloat(response.data.data.location.longitude)]
                this.marker = [parseFloat(response.data.data.location.latitude), parseFloat(response.data.data.location.longitude)]
                this.nameModel = response.data.data.communityName
                this.idModel = response.data.data.communityId.replace('@', '')
                this.descriptionModel = response.data.data.descriptionCommunity
                if (response.data.data.imageCommunity) {
                    this.imageUrl = response.data.data.imageCommunity
                }
                this.getAllUser()
                const getStorage = JSON.parse(sessionStorage.getItem('credentials'))
                this.filterEmail = getStorage.email
            }).catch(error => {
                console.log(error)
            }).finally(() => (this.isLoading = false))
        },
        changeMemberType(item) {
            if (this.listMember.filter(member => member.memberType === 'pic').length === 1 && item.memberType === 'pic') {
                this.$swal
                    .fire({
                        title: "warning",
                        text: "Can't change, because there's only one pic",
                        type: "warning",
                        icon: "warning",
                    })
            } else {
                var type;
                if (item.memberType === 'pic') {
                    type = 'pic'
                } else {
                    type = 'member'
                }

                this.$swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Change",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true
                            let id = this.$route.params.id
                            var formData = new FormData();
                            formData.append('id', id)
                            formData.append('idUser', item.id)
                            formData.append('type', type)
                            axios.post(this.$store.state.API_PATH_COMMUNITY + 'set-member-type', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            }).then((response) => {
                                this.$swal
                                    .fire({
                                        title: "success",
                                        text: response.data.message,
                                        type: "success",
                                        icon: "success",
                                    })
                                    .then(function () {
                                        location.reload();
                                    });
                            }).catch(error => {
                                console.log(error)
                            }).finally(() => (this.isLoading = false))
                        }
                    })
            }
        },
        approveMember(item) {
            this.$swal
                .fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Approve",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true
                        let communityID = this.$route.params.id
                        var formData = new FormData();
                        formData.append('communityID', communityID)
                        formData.append('userID', item.id)
                        axios.post(this.$store.state.API_PATH_COMMUNITY + 'approve-member', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }).then((response) => {
                            this.$swal
                                .fire({
                                    title: "success",
                                    text: response.data.message,
                                    type: "success",
                                    icon: "success",
                                })
                                .then(function () {
                                    location.reload();
                                });
                        }).catch(error => {
                            console.log(error)
                        }).finally(() => (this.isLoading = false))
                    }
                })
        },
    },
};
</script>
