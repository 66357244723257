import { createApp } from 'vue'
import { initializeApp } from 'firebase/app'
import App from './App.vue'
import router from './router'
import store from './store'
import vue3GoogleLogin from 'vue3-google-login'
import vuetify from './plugins/vuetify'
import Swal from 'sweetalert2'
import VueCookies from 'vue-cookies'
import CKEditor from '@ckeditor/ckeditor5-vue'
// import mavonEditor from 'mavon-editor'
// import 'mavon-editor/dist/css/index.css'

const app = createApp(App)

app.use(vue3GoogleLogin, {
    clientId: '1025197982213-f3ame4ueao186jrse3gekguagqgl0tev.apps.googleusercontent.com'
})

const firebaseConfig = {
    apiKey: "AIzaSyAs-UTmKadMFiCfrDanHC20qaRRvws4jaQ",
    authDomain: "comeandfollowme-d6272.firebaseapp.com",
    projectId: "comeandfollowme-d6272",
    storageBucket: "comeandfollowme-d6272.appspot.com",
    messagingSenderId: "1025197982213",
    appId: "1:1025197982213:web:e0d56dab03f60e61aa126c",
    measurementId: "G-2S7DDNQ8F8"
};

initializeApp(firebaseConfig)

app.config.globalProperties.$swal = Swal
app.use(VueCookies, { expires: '7d' })
app.use(CKEditor)
    // app.use(mavonEditor)
app.use(store).use(router).use(vuetify).mount('#app')