<template>
  <v-navigation-drawer app permanent class="sidebar">
    <br>
    <div class="user-info">
      <div class="user-avatar">
        <img class="userImage"
          src="https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg"
          alt="User Image" />
      </div>
      <div class="user-name">
        {{ userName }}
      </div>
    </div>

    <hr>

    <v-list v-if="loginType === 'role_1'">
      <v-list-item @click="homeParent">
        <v-icon>mdi-home</v-icon>
        <v-list-item-title>HOME</v-list-item-title>
      </v-list-item>
      <v-list-item @click="topicParent">
        <v-icon>mdi-book-open-variant</v-icon>
        <v-list-item-title>TOPIC</v-list-item-title>
      </v-list-item>
      <v-list-item @click="articleParent">
        <v-icon>mdi-newspaper-variant-multiple</v-icon>
        <v-list-item-title>ARTICLE</v-list-item-title>
      </v-list-item>
      <v-list-item @click="reflectionParent">
        <v-icon>mdi-book-plus-outline</v-icon>
        <v-list-item-title>REFLECTION</v-list-item-title>
      </v-list-item>
      <v-list-item @click="biblePlanParent">
        <v-icon>mdi-book-open-variant</v-icon>
        <v-list-item-title>BIBLE PLAN</v-list-item-title>
      </v-list-item>
      <v-list-item @click="agenciesParent">
        <v-icon>mdi mdi-badge-account</v-icon>
        <v-list-item-title>ORGANIZATION</v-list-item-title>
      </v-list-item>
      <v-list-item @click="communityParent">
        <v-icon>mdi-account-multiple-outline</v-icon>
        <v-list-item-title>COMMUNITY</v-list-item-title>
      </v-list-item>
      <v-list-item @click="listUserParent">
        <v-icon>mdi-account-group-outline</v-icon>
        <v-list-item-title>LIST USERS</v-list-item-title>
      </v-list-item>
      <v-list-item @click="editParent">
        <v-icon>mdi-cog-outline</v-icon>
        <v-list-item-title>SETTING</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-list v-if="loginType === 'role_2'">
      <v-list-item @click="homeOrganization">
        <v-icon>mdi-home</v-icon>
        <v-list-item-title>HOME</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-list v-if="loginType === 'community'">
      <v-list-item @click="homeCommunity">
        <v-icon>mdi-home</v-icon>
        <v-list-item-title>HOME</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-list-item @click="logOut">
      <v-icon>mdi-logout</v-icon>
      <v-list-item-title>LOGOUT</v-list-item-title>
    </v-list-item>
  </v-navigation-drawer>
</template> 

<script>
export default {
  data() {
    return {
      loginType: '',
      sidebarOpen: true,
      userName: JSON.parse(sessionStorage.getItem("credentials")).username,
    };
  },
  methods: {
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    loginRole() {
      this.loginType = sessionStorage.getItem('role')
    },
    homeParent() {
      this.$router.push({ name: 'home' })
    },
    homeOrganization() {
      this.$router.push({ name: 'homeorganization' })
    },
    topicParent() {
      this.$router.push({ name: 'topic' })
    },
    agenciesParent() {
      this.$router.push({ name: 'listorganization' })
    },
    communityParent() {
      this.$router.push({ name: 'listcommunity', params: { id: sessionStorage.getItem('agencies') } })
    },
    listUserParent() {
      this.$router.push({ name: 'listusers' })
    },
    articleParent() {
      this.$router.push({ name: 'listarticle', params: { id: sessionStorage.getItem('agencies') } })
    },
    reflectionParent() {
      this.$router.push({ name: 'reflection', params: { id: sessionStorage.getItem('agencies') } })
    },
    biblePlanParent() {
      this.$router.push({ name: 'bibleplan', params: { id: sessionStorage.getItem('agencies') } })
    },
    editParent() {
      this.$router.push({ name: 'editparent', params: { id: sessionStorage.getItem('agencies') } })
    },
    homeCommunity() {
      this.$router.push({ name: 'homecommunity' })
    },
    logOut() {
      sessionStorage.clear();
      this.$router.push({ name: 'login' })
    }
  },
  mounted() {
    this.loginRole();
  },
};
</script>

<style>
.sidebar .v-list-item-title {
  color: white;
}

.sidebar .v-list-item {
  cursor: pointer;
}

.sidebar .v-list-item:hover {
  background-color: rgb(90, 3, 230);
}

.sidebar .v-navigation-drawer__border {
  background-color: transparent;
}

.v-navigation-drawer .v-navigation-drawer__border {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}

.sidebar-closed {
  width: 70px;
  overflow: hidden;
}

.sidebar-closed .v-list-item {
  text-align: center;
  width: 100%;
}

.sidebar-closed .v-list-item-icon {
  margin: 0;
}

.user-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.userImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
