<template>
    <v-container fluid>
        <v-dialog v-model="isLoading" persistent width="auto">
            <div class="text-center">
                <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
            </div>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card style="background-color: #50b454; color: white;" title="EDIT ARTICLE" elevation="10"></v-card>
                <br>
                <v-card elevation="10">
                    <v-card-text>
                        <v-form @submit.prevent="submit">
                            <v-row>
                                <v-col>
                                    <v-select v-model="isPromoModel" :items="isPromo" item-text="name" item-value="value"
                                        label="Article Type" variant="outlined"></v-select>
                                </v-col>
                                <v-col>
                                    <v-autocomplete v-model="languageModel" :items="language" item-value="alpha2"
                                        item-title="english" :rules="languageRules" label="Select Language"
                                        variant="outlined"></v-autocomplete>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="titleModel" :rules="titleRules" color="primary" label="Title"
                                        variant="outlined"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-file-input v-model="imageModel" @change="onFileSelectedImage"
                                        @click:clear="clearFileImage" prepend-icon="mdi-camera" label="Upload Image"
                                        accept="image/png, image/jpeg, image/bmp" :show-size="1000"
                                        variant="outlined"></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row v-if="imageUrl">
                                <v-col>
                                    <img style="max-width: 50%;" :src="imageUrl" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field prepend-icon="mdi-video" v-model="urlVideoModel" :rules="urlVideoRules"
                                        color="primary" label="URL Video" variant="outlined"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-combobox v-model="tagModel" :rules="[tagRules]" chips clearable label="Tags" multiple
                                        variant="outlined">
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                            <v-chip v-bind="attrs" :model-value="selected" closable @click="select"
                                                @click:close="remove(item)">
                                                <strong>{{ item }}</strong>&nbsp;
                                                <span>(interest)</span>
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col>
                                    <datepicker ref="datepicker1" v-model="date" :format="dateFormat" :value="date">
                                    </datepicker>
                                </v-col>
                            </v-row>
                            <br>
                            <hr>
                            <br>
                            <v-row v-for="(p, indexParent) of parent" :key="indexParent">
                                <v-col>
                                    <h3 class="black--text">Section {{ indexParent + 1 }}</h3>
                                    <br>
                                    <v-text-field v-model="p.valueSubtitle" :rules="subtitleRules" label="Subtitle"
                                        variant="outlined"></v-text-field>
                                    <br>
                                    <ckeditor :editor="editor" v-model="p.valueContentText" required />
                                    <!-- <mavon-editor v-model="p.valueContentText" :rules="contentTextRules" :subfield="true"
                                        :language="'en'" /> -->
                                    <v-col class="text-right">
                                        <v-btn v-if="indexParent == parent.length - 1" class="mx-2" fab dark small
                                            color="blue" @click="tambahInputParent">
                                            <v-icon dark> mdi-plus </v-icon>
                                        </v-btn>
                                        <v-btn v-if="indexParent == parent.length - 1 && indexParent != 0" class="mx-2" fab
                                            dark small color="red" @click="deleteInputParent(indexParent)">
                                            <v-icon dark> mdi-minus </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-col>
                            </v-row>
                            <br><br>
                            <v-btn type="submit" color="success" class="mt-4" block> SUBMIT </v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import { ref } from 'vue'
import Datepicker from 'vuejs3-datepicker'
import moment from 'moment'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'


export default {
    mounted() {
        this.getData()
    },
    components: {
        Datepicker,
    },
    data() {
        return {
            editor: ClassicEditor,
            parent: [
                {
                    valueSubtitle: "",
                    valueContentText: "",
                },
            ],
            isLoading: false,
            dateFormat: ref('yyyy-MM-dd'),
            date: null,
            isPromo: [
                {
                    title: "PROMO",
                    value: true
                }, {
                    title: "NOT PROMO",
                    value: false
                }
            ],
            language: [],
            isPromoModel: false,
            languageModel: '',
            titleModel: '',
            imageModel: null,
            urlVideoModel: '',
            tagModel: [],
            imageUrl: null,
            languageRules: [
                v => !!v || 'Language is required',
            ],
            titleRules: [
                v => !!v || 'Title Name is required',
            ],
            urlVideoRules: [
                v => !!v || 'URL is required',
            ],
            subtitleRules: [
                v => !!v || 'Subtitle is required',
            ],
            contentTextRules: [
                v => !!v || 'Content is required',
            ]
        };
    },
    methods: {
        tagRules(value) {
            return !!value.length || "Tag is required";
        },
        tambahInputParent() {
            this.parent.push({
                valueSubtitle: "",
                valueContentText: "",
            });
        },
        deleteInputParent(indexParent) {
            this.parent.splice(indexParent, 1);
        },
        remove(item) {
            this.chips.splice(this.chips.indexOf(item), 1)
        },
        onFileSelectedImage(event) {
            const file = event.target.files[0]
            this.selectedFile = file
            this.imageUrl = file ? URL.createObjectURL(file) : null
        },
        clearFileImage() {
            this.imageModel = null
            this.imageUrl = null
        },
        async getLanguage() {
            await axios.get(this.$store.state.API_PATH_AGENCIES + 'get-language').then((response) => {
                this.language = response.data.data
            }).catch(error => {
                console.log(error)
            })
        },
        async getData() {
            let id = this.$route.params.id
            this.isLoading = true
            await axios.get(this.$store.state.API_PATH_ARTICLE + 'get-detail-article', { params: { id: id } }).then((response) => {
                this.languageModel = response.data.data.language
                this.titleModel = response.data.data.title
                this.imageUrl = response.data.data.images[1]
                this.urlVideoModel = response.data.data.videos
                this.tagModel = response.data.data.tags
                this.date = new Date(response.data.data.schedule)
                this.isPromoModel = response.data.data.promo
                this.parent[0].valueSubtitle = response.data.data.subtitle[0]
                this.parent[0].valueContentText = response.data.data.contentText[0]
                for (var i = 1; i < response.data.data.subtitle.length; i++) {
                    this.parent.push({
                        valueSubtitle: response.data.data.subtitle[i],
                        valueContentText: response.data.data.contentText[i],
                    })
                }
                this.getLanguage()
            }).catch(error => {
                console.log(error)
            }).finally(() => (this.isLoading = false))
        },
        async submit(event) {
            const result = await event
            const parse = JSON.parse(JSON.stringify(result))
            let credentials = JSON.parse(sessionStorage.getItem("credentials"));

            if (parse.valid) {
                const formattedDate = moment(this.date).format('yyyy-MM-DD')
                const subtitle = new Array()
                const contentText = new Array()
                this.parent.forEach((item) => {
                    subtitle.push(item.valueSubtitle)
                    contentText.push(item.valueContentText)
                })
                this.$swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Edit",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true
                            let id = this.$route.params.id
                            const jsonString = JSON.stringify(this.parent)
                            var formData = new FormData()
                            formData.append('id', id)
                            formData.append('isPromo', this.isPromoModel)
                            formData.append('language', this.languageModel)
                            formData.append('title', this.titleModel)

                            if (this.imageModel) {
                                formData.append('images', this.imageModel[0])
                            }

                            formData.append('videos', this.urlVideoModel)
                            formData.append('schedule', formattedDate)
                            formData.append('content', jsonString)
                            formData.append('tags', this.tagModel)
                            formData.append("updatedBy", credentials.id);

                            axios.post(this.$store.state.API_PATH_ARTICLE + 'update-article', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            }).then((response) => {
                                this.$swal
                                    .fire({
                                        title: "success",
                                        text: response.data.message,
                                        type: "success",
                                        icon: "success",
                                    })
                                    .then(function () {
                                        location.reload();
                                    });
                            }).catch(error => {
                                console.log(error)
                            }).finally(() => (this.isLoading = false))
                        }
                    })
            } else {
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Check Required Input!",
                })
            }
        }
    },
};
</script>
