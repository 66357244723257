<template>
    <v-container fluid>
        <v-dialog v-model="isLoading" persistent width="auto">
            <div class="text-center">
                <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
            </div>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card style="background-color: #50b454; color: white;" title="LIST BIBLE PLAN" elevation="10"></v-card>
                <br>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-inner-icon="mdi-magnify" label="Search" single-line hide-details
                    variant="solo"></v-text-field>
                <br>
                <v-card elevation="10">
                    <v-data-table :headers="headers" :items="list" :search="search" align="center" outlined>
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-dialog>
                                    <template v-slot:activator="{ props }">
                                        <div class="text-left"
                                            style="position: absolute; margin-left: 1%; background-color: white;">
                                            <v-btn @click="openEditDialog()" class="white--text" color="red"
                                                elevation="2"><v-icon> mdi-delete
                                                </v-icon></v-btn>
                                        </div>
                                        <v-btn color="primary" dark class="mb-2" @click="createItem()" v-bind="props">
                                            <v-icon icon="mdi-plus"></v-icon> CREATE
                                        </v-btn>
                                    </template>
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon size="small" class="me-2" @click="editItem(item.raw)">
                                mdi-pencil
                            </v-icon>
                            <v-icon size="small" class="me-2" @click="deleteItem(item.raw)">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="dialog" fullscreen :scrim="false" transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Trash</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <!-- <v-list lines="two" subheader>
                        <v-list-subheader>Trash</v-list-subheader>
                    </v-list> -->
                    <v-divider></v-divider>
                    <v-list lines="two" subheader>
                        <v-col>
                            <v-text-field v-model="searchDelete" append-inner-icon="mdi-magnify" label="Search" single-line
                                hide-details variant="solo"></v-text-field>
                            <br>
                            <v-data-table :headers="headers" :items="listDelete" :search="searchDelete" align="center"
                                outlined>
                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-divider class="mx-4" inset vertical></v-divider>
                                        <v-spacer></v-spacer>
                                        <v-dialog>
                                        </v-dialog>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-layout justify-center>
                                        <v-icon @click="restoreItem(item)"> mdi-rotate-right </v-icon>
                                    </v-layout>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-list>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import { VDataTable } from 'vuetify/labs/VDataTable'

export default {
    mounted() {
        this.getList()
    },
    components: {
        VDataTable,
    },
    data() {
        return {
            dialog: false,
            notifications: false,
            sound: true,
            widgets: false,
            isLoading: false,
            search: '',
            searchDelete: '',
            list: [],
            listDelete: [],
            headers: [
                {
                    align: 'start',
                    key: 'id',
                    sortable: true,
                    title: 'ID',
                },
                {
                    align: 'start',
                    key: 'title',
                    sortable: true,
                    title: 'TITLE',
                },
                {
                    align: 'start',
                    key: 'schedule',
                    sortable: true,
                    title: 'SCHEDULE',
                },
                {
                    align: 'start',
                    key: 'createdBy',
                    sortable: true,
                    title: 'CREATED BY',
                },
                {
                    align: 'start',
                    key: 'updatedBy',
                    sortable: true,
                    title: 'UPDATED BY',
                },
                {
                    align: 'start',
                    key: 'deletedBy',
                    sortable: true,
                    title: 'DELETED BY',
                },
                { title: "ACTIONS", key: "actions", sortable: false },
            ],
        }
    },
    methods: {
        openEditDialog() {
            this.dialog = true
            this.getListDelete()
        },
        createItem() {
            let id = this.$route.params.id
            this.$router.push({ name: 'createbibleplancommunity', params: { id: id } })
        },
        editItem(item) {
            this.$router.push({ name: 'editbibleplancommunity', params: { id: item.id } })
        },
        restoreItem(item) {
            this.dialog = false
            this.$swal
                .fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, restore",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true
                        let credentials = JSON.parse(sessionStorage.getItem("credentials"));
                        var formData = new FormData();
                        formData.append("id", item.value)
                        formData.append("updatedBy", credentials.id)
                        axios.post(this.$store.state.API_PATH_BIBLEPLAN_COMMUNITY + 'restore-bibleplan', formData).then((response) => {
                            this.$swal
                                .fire({
                                    title: "success",
                                    text: response.data.message,
                                    type: "success",
                                    icon: "success",
                                })
                                .then(function () {
                                    location.reload();
                                });
                        }).catch(error => {
                            console.log(error)
                        }).finally(() => (this.isLoading = false))
                    }
                })
        },
        deleteItem(item) {
            this.$swal
                .fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, remove",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true
                        let credentials = JSON.parse(sessionStorage.getItem("credentials"));
                        axios.delete(this.$store.state.API_PATH_BIBLEPLAN_COMMUNITY + 'delete-bibleplan', { params: { id: item.id, deletedBy: credentials.id } }).then((response) => {
                            this.$swal
                                .fire({
                                    title: "success",
                                    text: response.data.message,
                                    type: "success",
                                    icon: "success",
                                })
                                .then(function () {
                                    location.reload();
                                });
                        }).catch(error => {
                            console.log(error)
                        }).finally(() => (this.isLoading = false))
                    }
                })
        },
        async getList() {
            this.isLoading = true
            let communityID = this.$route.params.id
            await axios.get(this.$store.state.API_PATH_BIBLEPLAN_COMMUNITY + 'list-bibleplan', { params: { communityID: communityID, publish: true } }).then((response) => {
                this.list = response.data.data
            }).catch(error => {
                console.log(error)
            }).finally(() => (this.isLoading = false))
        },
        async getListDelete() {
            this.isLoading = true
            let communityID = this.$route.params.id
            await axios.get(this.$store.state.API_PATH_BIBLEPLAN_COMMUNITY + 'list-bibleplan', { params: { communityID: communityID, publish: false } }).then((response) => {
                this.listDelete = response.data.data
            }).catch(error => {
                console.log(error)
            }).finally(() => (this.isLoading = false))
        },
    }
}
</script>