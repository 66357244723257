<template>
    <v-container fluid>
        <v-dialog v-model="isLoading" persistent width="auto">
            <div class="text-center">
                <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
            </div>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card style="background-color: #50b454; color: white" title="CREATE BIBLE PLAN" elevation="10"></v-card>
                <br />
                <v-card elevation="10">
                    <v-card-text>
                        <v-form @submit.prevent="submit">
                            <v-row>
                                <v-col>
                                    <v-combobox v-model="languageModel" label="Language" :items="language"
                                        item-title="english" item-value="alpha2" :rules="languageRules" variant="outlined"
                                        @click="getTopic" @change="getTopic"></v-combobox>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="titleModel" :rules="titleRules" color="primary" label="Title"
                                        variant="outlined"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-combobox v-model="topicModel" label="Topic" :items="topic" item-title="topic"
                                        item-value="id" :rules="topicRules" variant="outlined"></v-combobox>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="authorModel" :rules="authorRules" color="primary" label="Author"
                                        variant="outlined"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-file-input v-model="imageModel" :rules="imageRules" @change="onFileSelectedImage"
                                        @click:clear="clearFileImage" prepend-icon="mdi-camera" label="Upload Image"
                                        accept="image/png, image/jpeg, image/bmp" :show-size="1000"
                                        variant="outlined"></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row v-if="imageUrl">
                                <v-col>
                                    <img style="max-width: 50%" :src="imageUrl" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-combobox v-model="tagModel" :rules="[tagRules]" chips clearable label="Tags" multiple
                                        variant="outlined">
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                            <v-chip v-bind="attrs" :model-value="selected" closable @click="select"
                                                @click:close="remove(item)">
                                                <strong>{{ item }}</strong>&nbsp;
                                                <span>(interest)</span>
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col>
                                    <datepicker ref="datepicker1" v-model="date" :format="dateFormat" :value="date">
                                    </datepicker>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea v-model="descriptionModel" :rules="descriptionRules" label="Description"
                                        multiple variant="outlined"></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row v-for="(p, indexParent) of parent" :key="indexParent">
                                <v-col>
                                    <v-textarea
                                        :label="'Day - ' + (indexParent + 1) + ' (Reflection) | If there is no reflection, please fill in the number 0 (zero)'"
                                        multiple variant="outlined" v-model="p.valueReflection" :rules="reflectionRules"
                                        :config="p.editorConfig" required></v-textarea>
                                </v-col>

                                <!-- NATS -->
                                <v-col cols="3">
                                    <v-text-field v-model="p.valueNats" :rules="natsRules" label="Nats Alkitab" required
                                        prepend-icon="mdi-pencil" variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="1">
                                    <v-btn variant="flat" color="green" v-if="indexParent == parent.length - 1"
                                        @click="tambahInputParent">
                                        <v-icon dark> mdi-plus </v-icon></v-btn>
                                </v-col>
                                <v-col cols="1">
                                    <v-btn variant="flat" color="red"
                                        v-if="indexParent == parent.length - 1 && indexParent != 0"
                                        @click="deleteInputParent(indexParent)"><v-icon dark> mdi-minus </v-icon></v-btn>
                                </v-col>
                            </v-row>
                            <v-btn type="submit" color="success" class="mt-4" block>
                                UPDATE
                            </v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { ref } from "vue";
import Datepicker from "vuejs3-datepicker";
import moment from "moment";
import axios from "axios";

export default {
    mounted() {
        this.getDetail()
    },
    components: {
        Datepicker,
    },
    data() {
        return {
            imageUrl: null,
            isLoading: false,
            dateFormat: ref("yyyy-MM-dd"),
            date: null,
            language: [],
            languageModel: [],
            titleModel: "",
            imageModel: null,
            tagModel: [],
            descriptionModel: "",
            authorModel: "",
            topic: [],
            topicModel: "",
            parent: [
                {
                    valueNats: "",
                    valueReflection: "",
                },
            ],
            languageRules: [(v) => !!v || "Language is required"],
            titleRules: [(v) => !!v || "Title is required"],
            imageRules: [
                (value) =>
                    !value ||
                    !value.some((file) => file.size > 2e6) ||
                    "Image size must be less than 2 MB",
            ],
            descriptionRules: [(v) => !!v || "Description is required"],
            reflectionRules: [(v) => !!v || "Reflection is required"],
            natsRules: [(v) => !!v || "Nats is required"],
            authorRules: [(v) => !!v || "Author is required"],
            topicRules: [(v) => !!v || "Topic is required"],
        };
    },
    methods: {
        tagRules(value) {
            return !!value.length || "Tag us required";
        },
        onFileSelectedImage(event) {
            const file = event.target.files[0];
            this.selectedFile = file;
            this.imageUrl = file ? URL.createObjectURL(file) : null;
        },
        clearFileImage() {
            this.imageModel = null;
            this.imageUrl = null;
        },
        remove(item) {
            this.chips.splice(this.chips.indexOf(item), 1);
        },
        tambahInputParent() {
            this.parent.push({
                valueNats: "",
                valueRenungan: "",
            });
        },
        deleteInputParent(indexParent) {
            this.parent.splice(indexParent, 1);
        },
        async getLanguage() {
            await axios
                .get(this.$store.state.API_PATH_AGENCIES + "get-language")
                .then((response) => {
                    this.language = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async getTopic() {
            this.topicModel = ""
            await axios
                .get(this.$store.state.API_PATH_TOPIC + "list-topic", { params: { language: this.languageModel.alpha2 } })
                .then((response) => {
                    this.topic = response.data.data;
                })
        },
        async getDetail() {
            let id = this.$route.params.id;
            await axios
                .get(this.$store.state.API_PATH_BIBLEPLAN_COMMUNITY + "get-bibleplan-byId", {
                    params: { id: id },
                })
                .then((response) => {
                    this.isLoading = true
                    this.getLanguage()
                    var data = response.data.data
                    this.languageModel = data.lang[0]
                    this.getTopic()
                    this.titleModel = data.title
                    this.topicModel = data.topic[0]
                    this.authorModel = data.author
                    this.imageUrl = data.image
                    this.tagModel = data.tag
                    this.date = data.schedule
                    this.descriptionModel = data.description
                    this.parent[0].valueReflection = data.reflection[0];
                    this.parent[0].valueNats = response.data.data.nats[0];
                    for (var i = 1; i < response.data.data.nats.length; i++) {
                        this.parent.push({
                            valueReflection: response.data.data.reflection[i],
                            valueNats: response.data.data.nats[i],
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                }).finally(() => (this.isLoading = false));
        },
        async submit(event) {
            const result = await event;
            const parse = JSON.parse(JSON.stringify(result));

            if (parse.valid) {
                const formattedDate = moment(this.date).format("yyyy-MM-DD");
                const nats = new Array();
                const reflection = new Array();
                this.parent.forEach((item) => {
                    nats.push(item.valueNats);
                    reflection.push(item.valueReflection);
                });

                this.$swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Update",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true;
                            let id = this.$route.params.id;
                            let credentials = JSON.parse(sessionStorage.getItem("credentials"));

                            var formData = new FormData();
                            formData.append("id", id);
                            formData.append("title", this.titleModel);

                            if (this.imageModel != null) {
                                formData.append("image", this.imageModel[0]);
                            }

                            formData.append("description", this.descriptionModel);
                            formData.append("nats", nats);
                            formData.append("reflection", reflection);
                            formData.append("author", this.authorModel);
                            formData.append("schedule", formattedDate);
                            formData.append("language", this.languageModel.alpha2);
                            formData.append("topic", this.topicModel.id);
                            formData.append("tag", this.tagModel);
                            formData.append("updatedBy", credentials.id);

                            axios
                                .post(
                                    this.$store.state.API_PATH_BIBLEPLAN_COMMUNITY + "update-bibleplan",
                                    formData,
                                    {
                                        headers: {
                                            "Content-Type": "multipart/form-data",
                                        },
                                    }
                                )
                                .then((response) => {
                                    this.$swal
                                        .fire({
                                            title: "success",
                                            text: response.data.message,
                                            type: "success",
                                            icon: "success",
                                        })
                                        .then(function () {
                                            location.reload();
                                        });
                                })
                                .catch((error) => {
                                    console.log(error);
                                })
                                .finally(() => (this.isLoading = false));
                        }
                    });
            } else {
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Check Required Input!",
                });
            }
        },
    },
};
</script>