<template>
    <v-container>
        <v-row>
            <v-col>
                <div class="chart-container">
                    <Bar id="my-chart-id" :options="chartOptions" :data="chartData" />
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: { Bar },
    data() {
        return {
            chartData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'Octtober', 'November', 'December'],
                datasets: [{ data: [40, 20, 12, 40, 20, 12, 40, 20, 12, 40, 20, 12] }]
            },
            chartOptions: {
                responsive: true,
            }
        }
    }
}
</script>

<style>
.chart-container {
    width: 40%;
    /* Set the desired width here, e.g., 500px or 100% */
}
</style>