import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

//const API_URL = "https://api1.alkitab.or.id/"
//const API_URL = "http://192.168.1.4:9090/"
const API_URL = "https://mvpdev.alkitab.or.id/"




export default createStore({
    plugins: [createPersistedState()],
    state: {
        API_PATH_USERS: API_URL + "users/api/identity-identified/users/",
        API_PATH_AGENCIES: API_URL + "agencies/api/identity-identified/agencies/",
        API_PATH_COMMUNITY: API_URL + "community/api/identity-identified/community/",
        API_PATH_BIBLEPLAN: API_URL + "bibleplan/api/identity-identified/web/",
        API_PATH_ARTICLE: API_URL + "article/api/identity-identified/article/",
        API_PATH_TOPIC: API_URL + "topic/api/identity-identified/web/",
        API_PATH_REFLECTION: API_URL + "reflection/api/identity-identified/web/",
        API_PATH_REFLECTION_COMMUNITY: API_URL + "reflectioncommunity/api/identity-identified/web/",
        API_PATH_BIBLEPLAN_COMMUNITY: API_URL + "bibleplancommunity/api/identity-identified/web/",
        API_PATH_ARTICLE_COMMUNITY: API_URL + "articlecommunity/api/identity-identified/web/",
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})