<template>
  <v-container fluid>
    <v-dialog v-model="isLoading" persistent width="auto">
      <div class="text-center">
        <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
      </div>
    </v-dialog>
    <v-row>
      <v-col>
        <v-card style="background-color: #50b454; color: white;" title="CREATE COMMUNITY" elevation="10"></v-card>
        <br>
        <v-card elevation="10">
          <v-card-text>
            <v-form @submit.prevent="submitForm">
              <v-row>
                <v-col>
                  <v-text-field color="primary" label="Community Name" v-model="nameModel" :rules="nameRules"
                    variant="outlined" required></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field @input="checkId" color="primary" label="Community Id" v-model="idModel"
                    :rules="[requiredIdRule, idExistsRule]" variant="outlined"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea label="Description Community" multiple variant="outlined" v-model="descriptionModel"
                    :rules="descriptionRules" required></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field color="primary" label="Search for a location..." v-model="searchQuery" variant="outlined"
                    required></v-text-field>
                </v-col>
                <v-col cols="1" style="margin-top: -0.6%">
                  <v-btn justify="center" @click="search" color="success" class="mt-4" text icon>
                    <v-icon>mdi-magnify</v-icon> </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div style="height:600px; max-width:100%">
                    <l-map @ready="onReady" @click="onMapClick" ref="map" :zoom="zoom" :center="center"
                      @locationfound="onLocationFound">
                      <l-tile-layer :url="url" layer-type="base" name="OpenStreetMap"></l-tile-layer>
                      <l-marker v-if="marker" :lat-lng="marker"></l-marker>
                    </l-map>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-combobox :items="pic" item-title="email" item-value="id" chips clearable
                    label="PIC (Person In Charge)" v-model="picModel" :rules="picRules" variant="outlined" required>
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip v-bind="attrs" :input-value="selected" close @click="select" @click:close="remove(item)">
                        <strong>{{ item.email }}</strong>&nbsp;
                        <span>(Email)</span>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-file-input v-model="imageModel" :rules="imageRules" @change="onFileSelectedImage"
                    @click:clear="clearFileImage" prepend-icon="mdi-camera" label="Upload Image"
                    accept="image/png, image/jpeg, image/bmp" :show-size="1000" variant="outlined"></v-file-input>
                </v-col>
              </v-row>
              <v-row v-if="imageUrl">
                <v-col>
                  <img style="max-width: 50%;" :src="imageUrl" />
                </v-col>
              </v-row>
              <v-btn type="submit" color="success" class="mt-4" block>SUBMIT</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import "leaflet/dist/leaflet.css"
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet"


export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  mounted() {
    this.getAllUser()
  },
  data() {
    return {
      map: null,
      searchQuery: '',
      zoom: 10,
      center: [40.689247, 74.044502],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      marker: null,
      isLoading: false,
      idExists: false,
      imageUrl: null,
      pdfUrl: null,
      language: [],
      pic: [],
      nameModel: '',
      idModel: null,
      descriptionModel: '',
      picModel: '',
      imageModel: null,
      nameRules: [
        v => !!v || 'Community Name is required',
      ],
      descriptionRules: [
        v => !!v || 'Description Community is required',
      ],
      picRules: [
        v => !!v || 'PIC is required',
        v => this.pic.includes(v) || 'Option not available'
      ],
      imageRules: [
        (value) => !!value || "Image is required",
        (value) =>
          !value ||
          !value.some((file) => file.size > 2e6) ||
          "Image size must be less than 2 MB",
      ],
    };
  },
  methods: {
    onReady(mapObject) {
      mapObject.locate();
    },
    onLocationFound(location) {
      const latlng = location.latlng
      this.center = [latlng.lat, latlng.lng]
      this.marker = [latlng.lat, latlng.lng]
    },
    onMapClick(event) {
      const latlng = event.latlng || event.target._latlng
      if (latlng !== "undifined") {
        this.center = [latlng.lat, latlng.lng]
        this.marker = [latlng.lat, latlng.lng]
      }
    },
    async search() {
      const query = encodeURIComponent(this.searchQuery);
      const url = `https://nominatim.openstreetmap.org/search?q=${query}&format=json`;
      const response = await fetch(url);
      const data = await response.json();
      if (data.length > 0) {
        const latlng = [data[0].lat, data[0].lon];
        this.marker = latlng;
        this.center = latlng;
      } else {
        alert('Location not found.');
      }
    },
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1)
    },
    onFileSelectedImage(event) {
      const file = event.target.files[0]
      this.selectedFile = file
      this.imageUrl = file ? URL.createObjectURL(file) : null
    },
    clearFileImage() {
      this.imageModel = null
      this.imageUrl = null
    },
    async checkId() {
      await axios.get(this.$store.state.API_PATH_COMMUNITY + 'check-community-id', { params: { communityId: this.idModel, type: 1 } }).then((response) => {
        this.idExists = response.data.data
      }).catch(error => {
        console.log(error)
      })
    },
    async getAllUser() {
      await axios.get(this.$store.state.API_PATH_USERS + 'get-all-user').then((response) => {
        this.pic = response.data.data
      }).catch(error => {
        console.log(error)
      })
    },
    async submitForm(event) {
      const result = await event
      const parse = JSON.parse(JSON.stringify(result))

      if (parse.valid) {
        this.$swal
          .fire({
            title: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Create",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.isLoading = true
              let agenciesId = sessionStorage.getItem('agencies')
              var formData = new FormData();
              formData.append('agenciesId', agenciesId)
              formData.append('communityName', this.nameModel)
              formData.append('communityId', this.idModel)
              formData.append('descriptionCommunity', this.descriptionModel)

              if (this.imageModel) {
                formData.append('imageCommunity', this.imageModel[0])
              }

              formData.append('latitude', String(this.marker[0]))
              formData.append('longitude', String(this.marker[1]))
              formData.append('member', this.picModel.id)

              axios.post(this.$store.state.API_PATH_COMMUNITY + 'create-community', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }).then((response) => {
                this.$swal
                  .fire({
                    title: "success",
                    text: response.data.message,
                    type: "success",
                    icon: "success",
                  })
                  .then(function () {
                    location.reload();
                  });
              }).catch(error => {
                console.log(error)
              }).finally(() => (this.isLoading = false))
            }
          })
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Check Required Input!",
        })
      }
    }
  },
  computed: {
    requiredIdRule() {
      return (value) => !!value || 'Community Id is required';
    },
    idExistsRule() {
      return () => !this.idExists || 'Community Id already exists'
    }
  }
};
</script>
