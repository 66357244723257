<template>
    <span v-if="$route.name === 'listtrashcommunity'">
        <ListTrashCommunity />
    </span>
    <span v-if="$route.name === 'listtrashorganization'">
        <ListTrashOrganization />
    </span>
</template>


<script>
import ListTrashCommunity from '@/components/community/ListTrashCommunityView.vue'
import ListTrashOrganization from '@/components/parent/ListTrashAgenciesSubParentView.vue'

export default {
    components: {
        ListTrashOrganization,
        ListTrashCommunity
    }
}
</script>