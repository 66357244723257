<template>
    <v-container fluid>
        <v-dialog v-model="isLoading" persistent width="auto">
            <div class="text-center">
                <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
            </div>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card style="background-color: #50b454; color: white;" title="EDIT ORGANIZATION" elevation="10"></v-card>
                <br>
                <div class="d-flex align-center flex-column pa-6">
                    <v-btn-toggle v-model="toggle" divided variant="outlined">
                        <v-btn @click="changeToEdit" icon="mdi-book-edit"></v-btn>
                        <v-btn @click="changeToMember" icon="mdi-account-group"></v-btn>
                    </v-btn-toggle>
                </div>
                <br>
                <v-card elevation="10">
                    <v-card-text>
                        <div v-if="isEdit">
                            <v-form @submit.prevent="submit">
                                <v-row>
                                    <v-col>
                                        <v-text-field @input="checkIdAgencies" color="primary" label="ID" v-model="idModel"
                                            :rules="[requiredIdRule, idExistsRule]" variant="outlined"></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field color="primary" label="Name" v-model="nameModel" :rules="nameRules"
                                            variant="outlined" required></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field type="email" color="primary" label="email" v-model="emailModel"
                                            :rules="emailRules" variant="outlined" required></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field color="primary" label="phone" v-model="phoneModel" :rules="phoneRules"
                                            variant="outlined" required></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field color="primary" label="Address" v-model="addressModel"
                                            :rules="addressRules" variant="outlined" required></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field color="primary" label="Postal Code" v-model="postalCodeModel"
                                            :rules="postalCodeRules" variant="outlined" required></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-autocomplete v-model="languageModel" :items="language" item-value="alpha2"
                                            item-title="english" :rules="languageRules" label="Select Language"
                                            variant="outlined"></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-file-input v-model="imageModel" @change="onFileSelectedImage"
                                            @click:clear="clearFileImage" :rules="imageRules" prepend-icon="mdi-camera"
                                            label="Upload Image" accept="image/png, image/jpeg, image/bmp" :show-size="1000"
                                            variant="outlined" required></v-file-input>
                                    </v-col>
                                </v-row>
                                <v-row v-if="imageUrl">
                                    <v-col>
                                        <img style="max-width: 50%;" :src="imageUrl" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-file-input @change="onFileSelectedDocument" @click:clear="clearFileDocument"
                                            v-model="documentModel" :rules="documentRules" prepend-icon="mdi-file-upload"
                                            label="Upload MOU Document" accept="application/pdf" :show-size="1000"
                                            variant="outlined" required></v-file-input>
                                    </v-col>
                                </v-row>
                                <v-row v-if="pdfUrl">
                                    <v-col>
                                        <iframe width="80%" height="500" frameborder="0" scrolling="no"
                                            :src="pdfUrl"></iframe>
                                    </v-col>
                                </v-row>
                                <v-btn type="submit" color="success" class="mt-4" block>SUBMIT</v-btn>
                            </v-form>
                        </div>
                        <div v-if="isMember">
                            <v-row>
                                <v-col>
                                    <v-combobox :items="member" item-title="email" item-value="id" chips clearable
                                        label="Add Member" v-model="memberModel" variant="outlined" required>
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                                @click:close="remove(item)">
                                                <strong>{{ item.email }}</strong>&nbsp;
                                                <span>(Email)</span>
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="1" style="margin-top: -0.9%">
                                    <v-btn justify="center" @click="addMember" color="primary" class="mt-4" text icon>
                                        <v-icon>mdi-plus</v-icon> </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="searchMember" append-inner-icon="mdi-magnify"
                                        label="Search Member" single-line hide-details variant="solo"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-data-table :headers="headers" :items="listMember" :search="searchMember"
                                        align="center" outlined>
                                        <template v-slot:top>
                                            <v-toolbar flat>
                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                <div class="text-left">
                                                    <h3>MEMBER LIST</h3>
                                                </div>
                                            </v-toolbar>
                                        </template>
                                        <template v-slot:[`item.actions`]="{ item }">
                                            <v-icon size="small" v-if="!item.props.title.email.includes(filterEmail)"
                                                class="me-2" @click="deleteItem(item.raw)">
                                                mdi-delete
                                            </v-icon>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { VDataTable } from 'vuetify/labs/VDataTable'
import axios from 'axios'

export default {
    mounted() {
        this.getData()

        if (sessionStorage.getItem('isMember') === 'true' && sessionStorage.getItem('isEdit') === 'false') {
            this.isMember = true
            this.isEdit = false
        } else {
            this.isMember = false
            this.isEdit = true
        }
    },
    components: {
        VDataTable,
    },
    data() {
        return {
            filterEmail: '',
            member: [],
            searchMember: '',
            toggle: null,
            isEdit: true,
            isMember: false,
            isLoading: false,
            idExists: false,
            imageUrl: null,
            pdfUrl: null,
            language: [],
            pic: [],
            role: [],
            nameModel: '',
            idModel: '',
            emailModel: '',
            phoneModel: '',
            addressModel: '',
            postalCodeModel: '',
            languageModel: '',
            picModel: '',
            roleModel: '',
            imageModel: null,
            documentModel: null,
            nameRules: [
                v => !!v || 'Name is required',
            ],
            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+/.test(v) || 'Email must be valid',
            ],
            phoneRules: [
                v => !!v || 'Phone is required',
            ],
            addressRules: [
                v => !!v || 'Address is required',
            ],
            postalCodeRules: [
                v => !!v || 'Postal Code is required',
            ],
            languageRules: [
                v => !!v || 'Language Code is required',
            ],
            picRules: [
                v => !!v || 'PIC is required',
            ],
            roleRules: [
                v => !!v || 'Role is required',
            ],
            imageRules: [
                (value) => !value || !value.some(file => file.size > 2e6) || 'Image size must be less than 2 MB'
            ],
            documentRules: [
                (value) => !value || !value.some(file => file.size > 1e6) || 'Document size must be less than 1 MB'
            ],
            headers: [
                {
                    title: 'EMAIL',
                    sortable: true,
                    key: 'email',
                },
                {
                    title: 'USERNAME',
                    sortable: true,
                    key: 'username',
                },
                { title: "ACTIONS", key: 'actions', sortable: false },
            ],
            listMember: [],
            memberModel: '',
        }
    },
    methods: {
        changeToEdit() {
            this.isEdit = true
            this.isMember = false
            sessionStorage.setItem('isEdit', true)
            sessionStorage.setItem('isMember', false)
        },
        changeToMember() {
            this.isMember = true
            this.isEdit = false
            sessionStorage.setItem('isMember', true)
            sessionStorage.setItem('isEdit', false)
        },
        remove(item) {
            this.chips.splice(this.chips.indexOf(item), 1)
        },
        onFileSelectedImage(event) {
            const file = event.target.files[0]
            this.selectedFile = file
            this.imageUrl = file ? URL.createObjectURL(file) : null
        },
        onFileSelectedDocument(event) {
            const file = event.target.files[0]
            this.selectedFile = file
            this.pdfUrl = file ? URL.createObjectURL(file) : null
        },
        clearFileImage() {
            this.imageModel = null
            this.imageUrl = null
        },
        clearFileDocument() {
            this.documentModel = null
            this.pdfUrl = null
        },
        async checkIdAgencies() {
            await axios.get(this.$store.state.API_PATH_AGENCIES + 'check-idagencies', { params: { type: 1, idAgencies: this.idModel } }).then((response) => {
                this.idExists = response.data.data
            }).catch(error => {
                console.log(error)
            })
        },
        async getLanguage() {
            await axios.get(this.$store.state.API_PATH_AGENCIES + 'get-language').then((response) => {
                this.language = response.data.data
            }).catch(error => {
                console.log(error)
            })
        },
        async getAllUser() {
            await axios.get(this.$store.state.API_PATH_USERS + 'get-all-user').then((response) => {
                this.member = response.data.data.filter(member => !this.listMember.some(listMember => listMember.email === member.email))
            }).catch(error => {
                console.log(error)
            })
        },
        async getData() {
            let id = this.$route.params.id
            this.isLoading = true
            await axios.get(this.$store.state.API_PATH_AGENCIES + 'get-agencies-by-id', { params: { id: id } }).then((response) => {
                this.idModel = response.data.data.idAgencies.replace('@', '')
                this.nameModel = response.data.data.name
                this.emailModel = response.data.data.contacts.email
                this.phoneModel = response.data.data.contacts.phone
                this.addressModel = response.data.data.address
                this.postalCodeModel = response.data.data.postalCode
                this.languageModel = response.data.data.country.code
                this.imageUrl = response.data.data.images[1]
                this.pdfUrl = response.data.data.documents[1]
                this.listMember = response.data.data.pic
                this.getAllUser()
                this.getLanguage()
                const getStorage = JSON.parse(sessionStorage.getItem('credentials'))
                this.filterEmail = getStorage.email
            }).catch(error => {
                console.log(error)
            }).finally(() => (this.isLoading = false))
        },
        addMember() {
            if (this.memberModel === "") {
                this.$swal
                    .fire({
                        title: "warning",
                        text: "Can't add, because text field empty",
                        type: "warning",
                        icon: "warning",
                    })
            } else {
                this.$swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Add",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true
                            let id = this.$route.params.id
                            var formData = new FormData();
                            formData.append('id', id)
                            formData.append('pic', this.memberModel.id)


                            axios.post(this.$store.state.API_PATH_AGENCIES + 'add-pic', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            }).then((response) => {
                                this.$swal
                                    .fire({
                                        title: "success",
                                        text: response.data.message,
                                        type: "success",
                                        icon: "success",
                                    })
                                    .then(function () {
                                        location.reload();
                                    });
                            }).catch(error => {
                                console.log(error)
                            }).finally(() => (this.isLoading = false))
                        }
                    })
            }
        },
        deleteItem(item) {
            if (this.listMember.length === 1) {
                this.$swal
                    .fire({
                        title: "warning",
                        text: "Can't remove, because there's only one pic",
                        type: "warning",
                        icon: "warning",
                    })
            } else {
                this.$swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, remove",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true
                            let id = this.$route.params.id
                            axios.delete(this.$store.state.API_PATH_AGENCIES + 'remove-pic', { params: { id: id, pic: item.id } }, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            }).then((response) => {
                                this.$swal
                                    .fire({
                                        title: "success",
                                        text: response.data.message,
                                        type: "success",
                                        icon: "success",
                                    })
                                    .then(function () {
                                        location.reload();
                                    });
                            }).catch(error => {
                                console.log(error)
                            }).finally(() => (this.isLoading = false))
                        }
                    })
            }
        },
        async submit(event) {
            const result = await event
            const parse = JSON.parse(JSON.stringify(result))
            if (parse.valid) {
                this.$swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Edit",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true
                            let id = this.$route.params.id

                            var formData = new FormData()
                            formData.append('id', id)
                            formData.append('idAgencies', this.idModel)
                            formData.append('name', this.nameModel)
                            formData.append('email', this.emailModel)
                            formData.append('phone', this.phoneModel)
                            formData.append('address', this.addressModel)
                            formData.append('code', this.languageModel)
                            formData.append('postalCode', this.postalCodeModel)

                            if (this.imageModel) {
                                formData.append('images', this.imageModel[0])
                            }

                            if (this.documentModel) {
                                formData.append('documents', this.documentModel[0])
                            }

                            axios.post(this.$store.state.API_PATH_AGENCIES + 'update-agencies', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            }).then((response) => {
                                this.$swal
                                    .fire({
                                        title: "success",
                                        text: response.data.message,
                                        type: "success",
                                        icon: "success",
                                    })
                                    .then(function () {
                                        location.reload();
                                    });
                            }).catch(error => {
                                console.log(error)
                            }).finally(() => (this.isLoading = false))
                        }
                    })
            } else {
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Check Required Input!",
                })
            }
        }
    },
    computed: {
        requiredIdRule() {
            return (value) => !!value || 'ID is required';
        },
        idExistsRule() {
            return () => !this.idExists || 'ID already exists'
        }
    }
}
</script>