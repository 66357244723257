<template>
    <v-container fluid>
        <v-dialog v-model="isLoading" persistent width="auto">
            <div class="text-center">
                <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
            </div>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card style="background-color: #50b454; color: white" title="CREATE REFLECTION" elevation="10"></v-card>
                <br />
                <v-card elevation="10">
                    <v-card-text>
                        <v-form @submit.prevent="submit">
                            <v-row>
                                <v-col>
                                    <v-combobox v-model="languageModel" label="Language" :items="language"
                                        item-title="english" item-value="alpha2" :rules="languageRules" variant="outlined"
                                        @click="getTopic" @change="getTopic"></v-combobox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="titleModel" :rules="titleRules" color="primary" label="Title"
                                        variant="outlined"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="natsModel" :rules="natsRules" color="primary" label="Nats"
                                        variant="outlined"></v-text-field>
                                </v-col>
                                <v-col>
                                    <datepicker ref="datepicker1" v-model="date" :format="dateFormat" :value="date">
                                    </datepicker>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-file-input v-model="imageModel" :rules="imageRules" @change="onFileSelectedImage"
                                        @click:clear="clearFileImage" prepend-icon="mdi-camera" label="Upload Image"
                                        accept="image/png, image/jpeg, image/bmp" :show-size="1000"
                                        variant="outlined"></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row v-if="imageUrl">
                                <v-col>
                                    <img style="max-width: 50%" :src="imageUrl" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea v-model="descriptionModel" :rules="descriptionRules" label="Description"
                                        multiple variant="outlined"></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="authorModel" :rules="authorRules" color="primary" label="Author"
                                        variant="outlined"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-combobox v-model="topicModel" label="Topic" :items="topic" item-title="topic"
                                        item-value="id" :rules="topicRules" variant="outlined"></v-combobox>
                                </v-col>
                                <v-col>
                                    <v-combobox v-model="tagModel" :rules="[tagRules]" chips clearable label="Tags" multiple
                                        variant="outlined">
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                            <v-chip v-bind="attrs" :model-value="selected" closable @click="select"
                                                @click:close="remove(item)">
                                                <strong>{{ item }}</strong>&nbsp;
                                                <span>(interest)</span>
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                            </v-row>
                            <v-btn type="submit" color="success" class="mt-4" block>
                                SUBMIT
                            </v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { ref } from "vue";
import Datepicker from "vuejs3-datepicker";
import moment from "moment";
import axios from "axios";

export default {
    mounted() {
        this.getLanguage()
    },
    components: {
        Datepicker,
    },
    data() {
        return {
            isLoading: false,
            titleModel: "",
            natsModel: "",
            language: [],
            languageModel: "",
            imageModel: null,
            imageUrl: null,
            descriptionModel: "",
            authorModel: "",
            tagModel: [],
            topic: [],
            topicModel: "",
            dateFormat: ref("yyyy-MM-dd"),
            date: ref(new Date()),
            titleRules: [(v) => !!v || "Title is required"],
            imageRules: [
                (value) => !!value || "Image is required",
                (value) =>
                    !value ||
                    !value.some((file) => file.size > 2e6) ||
                    "Image size must be less than 2 MB",
            ],
            natsRules: [(v) => !!v || "Nats is required"],
            descriptionRules: [(v) => !!v || "Description is required"],
            authorRules: [(v) => !!v || "Author is required"],
            topicRules: [(v) => !!v || "Topic is required"],
            languageRules: [(v) => !!v || "Language is required"],
        };
    },
    methods: {
        tagRules(value) {
            return !!value.length || "Tag us required";
        },
        onFileSelectedImage(event) {
            const file = event.target.files[0];
            this.selectedFile = file;
            this.imageUrl = file ? URL.createObjectURL(file) : null;
        },
        clearFileImage() {
            this.imageModel = null;
            this.imageUrl = null;
        },
        remove(item) {
            this.chips.splice(this.chips.indexOf(item), 1);
        },
        async getLanguage() {
            await axios
                .get(this.$store.state.API_PATH_AGENCIES + "get-language")
                .then((response) => {
                    this.language = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async getTopic() {
            this.topicModel = "";
            await axios
                .get(this.$store.state.API_PATH_TOPIC + "list-topic", { params: { language: this.languageModel.alpha2 } })
                .then((response) => {
                    this.topic = response.data.data;
                })
        },
        async submit(event) {
            const result = await event;
            const parse = JSON.parse(JSON.stringify(result));

            if (parse.valid) {
                const formattedDate = moment(this.date).format("yyyy-MM-DD");
                this.$swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Create",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            console.log(this.topicModel.id)
                            this.isLoading = true
                            let agenciesID = this.$route.params.id;
                            let credentials = JSON.parse(sessionStorage.getItem("credentials"));

                            var formData = new FormData();
                            formData.append("agenciesID", agenciesID);
                            formData.append("title", this.titleModel);
                            formData.append("nats", this.natsModel);
                            formData.append("language", this.languageModel.alpha2);
                            formData.append("image", this.imageModel[0]);
                            formData.append("description", this.descriptionModel);
                            formData.append("author", this.authorModel);
                            formData.append("schedule", formattedDate);
                            formData.append("topic", this.topicModel.id);
                            formData.append("tag", this.tagModel);
                            formData.append("createdBy", credentials.id);

                            axios
                                .post(
                                    this.$store.state.API_PATH_REFLECTION + "create-reflection",
                                    formData,
                                    {
                                        headers: {
                                            "Content-Type": "multipart/form-data",
                                        },
                                    }
                                )
                                .then((response) => {
                                    this.$swal
                                        .fire({
                                            title: "success",
                                            text: response.data.message,
                                            type: "success",
                                            icon: "success",
                                        })
                                        .then(function () {
                                            location.reload();
                                        });
                                })
                                .catch((error) => {
                                    console.log(error);
                                })
                                .finally(() => (this.isLoading = false));
                        }
                    });
            } else {
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Check Required Input!",
                });
            }
        },
    }
}
</script>