import { createRouter, createWebHistory } from 'vue-router'
// import Test from '@/views/TestView.vue'
import Login from '@/views/LoginView.vue'
import Home from '@/views/HomeView.vue'
import ListView from '@/views/ListView.vue'
import CreateView from '@/views/CreateView.vue'
import EditView from '@/views/EditView.vue'
import TrashView from '@/views/TrashView.vue'
import ChartView from '@/views/ChartView.vue'

const routes = [{
        path: '/test',
        name: 'test',
        component: ChartView,
        meta: { title: 'TEST' }
    },
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: { title: 'LOGIN | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: { title: 'HOME AGENCIES | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/home-organization',
        name: 'homeorganization',
        component: Home,
        meta: { title: 'HOME ORGANIZATION | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/home-community',
        name: 'homecommunity',
        component: Home,
        meta: { title: 'HOME COMMUNITY | IDENTITY:IDENTIFIED' }
    },

    // LIST
    {
        path: '/list-organization',
        name: 'listorganization',
        component: ListView,
        meta: { title: 'LIST ORGANIZATION | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/list-community/:id',
        name: 'listcommunity',
        component: ListView,
        meta: { title: 'LIST COMMUNITY | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/member-community/:id',
        name: 'membercommunity',
        component: ListView,
        meta: { title: 'MEMBER COMMUNITY | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/bibleplan/:id',
        name: 'bibleplan',
        component: ListView,
        meta: { title: 'BIBLE PLAN | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/list-article/:id',
        name: 'listarticle',
        component: ListView,
        meta: { title: 'LIST ARTICLE | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/list-users',
        name: 'listusers',
        component: ListView,
        meta: { title: 'LIST USERS | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/reflection/:id',
        name: 'reflection',
        component: ListView,
        meta: { title: 'REFLECTION | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/reflectioncommunity/:id',
        name: 'reflectioncommunity',
        component: ListView,
        meta: { title: 'REFLECTION | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/bibleplancommunity/:id',
        name: 'bibleplancommunity',
        component: ListView,
        meta: { title: 'BIBLE PLAN | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/articlecommunity/:id',
        name: 'articlecommunity',
        component: ListView,
        meta: { title: 'ARTICLE | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/list-community-organization/:id',
        name: 'listcommunityorganization',
        component: ListView,
        meta: { title: 'LIST COMMUNITY | IDENTITY:IDENTIFIED' }
    },

    // CREATE
    {
        path: '/create-organization',
        name: 'createorganization',
        component: CreateView,
        meta: { title: 'CREATE ORGANIZATION | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/create-community',
        name: 'createcommunity',
        component: CreateView,
        meta: { title: 'CREATE COMMUNITY | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/createbibleplan/:id',
        name: 'createbibleplan',
        component: CreateView,
        meta: { title: 'BIBLE PLAN| IDENTITY:IDENTIFIED' }
    },
    {
        path: '/create-article/:id',
        name: 'createarticle',
        component: CreateView,
        meta: { title: 'CREATE ARTICLE| IDENTITY:IDENTIFIED' }
    },
    {
        path: '/topic',
        name: 'topic',
        component: CreateView,
        meta: { title: 'TOPIC | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/createreflectioncommunity/:id',
        name: 'createreflectioncommunity',
        component: CreateView,
        meta: { title: 'REFLECTION | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/createreflection/:id',
        name: 'createreflection',
        component: CreateView,
        meta: { title: 'REFLECTION | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/createbibleplancommunity/:id',
        name: 'createbibleplancommunity',
        component: CreateView,
        meta: { title: 'BIBLE PLAN| IDENTITY:IDENTIFIED' }
    },
    {
        path: '/createarticlecommunity/:id',
        name: 'createarticlecommunity',
        component: CreateView,
        meta: { title: 'ARTICLE | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/create-community-organization/:id',
        name: 'createcommunityorganization',
        component: CreateView,
        meta: { title: 'COMMUNITY | IDENTITY:IDENTIFIED' }
    },


    // EDIT
    {
        path: '/edit-organization/:id',
        name: 'editorganization',
        component: EditView,
        meta: { title: 'EDIT ORGANIZATION | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/edit-community/:id',
        name: 'editcommunity',
        component: EditView,
        meta: { title: 'EDIT COMMUNITY | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/edit-article/:id',
        name: 'editarticle',
        component: EditView,
        meta: { title: 'EDIT ARTICLE | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/edit-parent/:id',
        name: 'editparent',
        component: EditView,
        meta: { title: 'EDIT AGENCIES | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/editreflectioncommunity/:id',
        name: 'editreflectioncommunity',
        component: EditView,
        meta: { title: 'REFLECTION | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/editbibleplan/:id',
        name: 'editbibleplan',
        component: EditView,
        meta: { title: 'BIBLEPLAN | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/editreflection/:id',
        name: 'editreflection',
        component: EditView,
        meta: { title: 'REFLECTION | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/editbibleplancommunity/:id',
        name: 'editbibleplancommunity',
        component: EditView,
        meta: { title: 'BIBLEPLAN | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/editarticlecommunity/:id',
        name: 'editarticlecommunity',
        component: EditView,
        meta: { title: 'ARTICLE | IDENTITY:IDENTIFIED' }
    },

    //TRASH
    {
        path: '/trash-organization',
        name: 'listtrashorganization',
        component: TrashView,
        meta: { title: 'TRASH | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/trash',
        name: 'lishtrashagenciessubparent',
        component: TrashView,
        meta: { title: 'TRASH | IDENTITY:IDENTIFIED' }
    },
    {
        path: '/trash-community/:id',
        name: 'listtrashcommunity',
        component: TrashView,
        meta: { title: 'TRASH | IDENTITY:IDENTIFIED' }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const token = sessionStorage.getItem("token")
    if (to.name !== "login" && !token) {
        next({ name: "login" })
    } else if (to.name === "login" && token) {
        document.title = to.meta.title
        next({ name: "home" })
    } else {
        document.title = to.meta.title
        next()
    }
})

export default router