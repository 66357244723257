<template>
    <v-container fluid>
        <v-dialog v-model="isLoading" persistent width="auto">
            <div class="text-center">
                <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
            </div>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card style="background-color: #50b454; color: white;" title="LIST DELETED ORGANIZATION"
                    elevation="10"></v-card>
                <br>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-inner-icon="mdi-magnify" label="Search" single-line hide-details
                    variant="solo"></v-text-field>
                <br>
                <v-card elevation="10">
                    <v-data-table :headers="headers" :items="listAgenciesSubParent" :search="search" align="center"
                        outlined>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon size="small" class="me-2" @click="setStatusActive(item.raw)">
                                mdi-restore
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import { VDataTable } from 'vuetify/labs/VDataTable'

export default {
    mounted() {
        this.getAgenciesListSubParent()
    },
    components: {
        VDataTable,
    },
    data() {
        return {
            isLoading: false,
            search: '',
            headers: [
                {
                    align: 'start',
                    key: 'name',
                    sortable: true,
                    title: 'NAME',
                },
                {
                    align: 'start',
                    key: 'idAgencies',
                    sortable: true,
                    title: 'ID ORGANIZATION',
                },
                { title: "ACTIONS", key: "actions", sortable: false },
            ],
            listAgenciesSubParent: [],
        }
    },
    methods: {
        setStatusActive(item) {
            var formData = new FormData()
            formData.append('id', item.id)
            formData.append('statusActive', true)
            this.$swal
                .fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, restore",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true
                        axios.post(this.$store.state.API_PATH_AGENCIES + 'set-statusactive', formData).then((response) => {
                            this.$swal
                                .fire({
                                    title: "success",
                                    text: response.data.message,
                                    type: "success",
                                    icon: "success",
                                })
                                .then(function () {
                                    location.reload();
                                });
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                }).catch(error => {
                    console.log(error)
                }).finally(() => (this.isLoading = false))
        },
        async getAgenciesListSubParent() {
            await axios.get(this.$store.state.API_PATH_AGENCIES + 'get-agencies', { params: { statusActive: false, role: "role_2" } }).then((response) => {
                this.listAgenciesSubParent = response.data.data
            }).catch(error => {
                console.log(error)
            })
        },
    },
}
</script>
