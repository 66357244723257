<template>
    <v-container fluid>
        <v-dialog v-model="isLoading" persistent width="auto">
            <div class="text-center">
                <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
            </div>
        </v-dialog>
        <v-dialog v-model="isLoading" persistent width="auto">
            <div class="text-center">
                <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
            </div>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card style="background-color: #50b454; color: white;" title="ORGANIZATION LIST" elevation="10"></v-card>
                <br>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-inner-icon="mdi-magnify" label="Search" single-line hide-details
                    variant="solo"></v-text-field>
                <br>
                <v-card elevation="10">
                    <v-data-table :headers="headers" :items="itemsWithStatusStrings" :search="search" align="center"
                        outlined>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon size="small" class="me-2" @click="communityItem(item.raw)">
                                mdi-account-multiple-outline
                            </v-icon>
                            <v-icon size="small" class="me-2" @click="editItem(item.raw)">
                                mdi-cog-outline
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import { VDataTable } from 'vuetify/labs/VDataTable'

export default {
    mounted() {
        this.getList()
    },
    components: {
        VDataTable,
    },
    data() {
        return {
            isLoading: false,
            search: '',
            headers: [
                {
                    align: 'start',
                    value: 'id',
                    sortable: true,
                    title: 'ID',
                },
                {
                    align: 'start',
                    value: 'name',
                    sortable: true,
                    title: 'NAME',
                },
                { title: "ACTIONS", key: "actions", sortable: false },
            ],
            list: [],
        }
    },
    methods: {
        communityItem(item) {
            this.$router.push({ name: 'listcommunityorganization', params: { id: item.id } })
        },
        editItem(item) {
            this.$router.push({ name: 'editorganization', params: { id: item.id } })
        },
        async getList() {
            this.isLoading = true
            let parse = JSON.parse(sessionStorage.getItem('credentials'))
            let email = parse.email
            await axios.get(this.$store.state.API_PATH_AGENCIES + 'list-organization', { params: { email: email } }).then((response) => {
                this.list = response.data.data
            }).catch(error => {
                console.log(error)
            }).finally(() => (this.isLoading = false))
        },
    },
    computed: {
        itemsWithStatusStrings() {
            return this.list.map(item => {
                return {
                    ...item,
                    statusActive: item.statusActive ? 'Active' : 'Inactive'
                };
            });
        }
    }
}
</script>
