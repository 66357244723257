<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <img style="max-width: 40%;" :src="images" alt="">
                <table class="table-home" style="text-align: center; margin-left: auto; margin-right: auto;">
                    <tr style="background-color: red; color: white;">
                        <th colspan="2">
                            Details
                        </th>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>
                            <p>{{ listAgencies.name }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Id</td>
                        <td>
                            <p>{{ listAgencies.idAgencies }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Country</td>
                        <td>
                            <p>{{ countryName }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Contacts
                        </td>
                        <td>
                            <p> Email: {{ email }} <br> Phone : {{ phone }}</p>
                        </td>
                    </tr>
                </table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'

export default {
    mounted() {
        this.getAgenciesListParent()
    },
    data() {
        return {
            listAgencies: [],
            images: '',
            countryName: '',
            phone: '',
            email: ''
        }
    },
    methods: {
        async getAgenciesListParent() {
            let role = sessionStorage.getItem("role")
            await axios.get(this.$store.state.API_PATH_AGENCIES + 'get-agencies',
                {
                    params: { statusActive: true, role: role }
                }
            ).then((response) => {
                this.listAgencies = response.data.data[0]
                sessionStorage.setItem("agencies", this.listAgencies.id)
                this.images = this.listAgencies.images[1]
                this.countryName = this.listAgencies.country.countryName
                this.email = this.listAgencies.contacts.email
                this.phone = this.listAgencies.contacts.phone
                sessionStorage.setItem("agencies", this.listAgencies.id)
            }).catch(error => {
                console.log(error)
            })
        }
    },
}
</script>

<style scoped>
table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
    width: 40%;
}
</style>
