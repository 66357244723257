<template>
    <v-container fluid>
        <v-dialog v-model="isLoading" persistent width="auto">
            <div class="text-center">
                <v-progress-circular indeterminate color="red" :size="54" :width="10"></v-progress-circular>
            </div>
        </v-dialog>
        <v-row>
            <v-col>
                <v-card style="background-color: #50b454; color: white;" title="EDIT COMMUNITY" elevation="10"></v-card>
                <br>
                <v-card elevation="10">
                    <v-card-text>
                        <v-form @submit.prevent="submitForm">
                            <v-row>
                                <v-col>
                                    <v-text-field color="primary" label="Community Name" v-model="nameModel"
                                        :rules="nameRules" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field @input="checkId" color="primary" label="Community Id" v-model="idModel"
                                        :rules="[requiredIdRule, idExistsRule]" variant="outlined"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea label="Description Community" multiple variant="outlined"
                                        v-model="descriptionModel" :rules="descriptionRules" required></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-combobox :items="member" item-title="email" item-value="id" chips clearable
                                        label="Add Member" v-model="memberModel" variant="outlined" required>
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                            <v-chip v-bind="attrs" :input-value="selected" close @click="select"
                                                @click:close="remove(item)">
                                                <strong>{{ item.email }}</strong>&nbsp;
                                                <span>(Email)</span>
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="2">
                                    <v-select label="Select Member Type" v-model="memberType" :items="['pic', 'member']"
                                        variant="outlined"></v-select>
                                </v-col>
                                <v-col cols="1" style="margin-top: -0.9%">
                                    <v-btn justify="center" @click="addMember" color="primary" class="mt-4" text icon>
                                        <v-icon>mdi-plus</v-icon> </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="searchMember" append-inner-icon="mdi-magnify"
                                        label="Search Member" single-line hide-details variant="solo"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-data-table :headers="headers" :items="listMember" :search="searchMember"
                                        align="center" outlined>
                                        <template v-slot:top>
                                            <v-toolbar flat>
                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                <div class="text-left">
                                                    <h3>MEMBER LIST</h3>
                                                </div>
                                            </v-toolbar>
                                        </template>
                                        <template v-slot:[`item.registered`]="{ item }">
                                            <span v-if="!item.props.title.email.includes(filterEmail)">
                                                <span v-if="item.props.title.registered.includes('P')">
                                                    <v-btn color="red" style="color: white" elevation="2" small
                                                        @click="approveMember(item.raw)">CONFIRM</v-btn>
                                                </span>
                                            </span>
                                        </template>
                                        <template v-slot:[`item.changeMember`]="{ item }">
                                            <span v-if="!item.props.title.email.includes(filterEmail)">
                                                <v-btn color="red" style="color: white" elevation="2" small
                                                    @click="changeMemberType(item.raw)">CHANGE</v-btn>
                                            </span>
                                        </template>
                                        <template v-slot:[`item.actions`]="{ item }">
                                            <span v-if="!item.props.title.email.includes(filterEmail)">
                                                <v-icon size="small" class="me-2" @click="deleteItem(item.raw)">
                                                    mdi-delete
                                                </v-icon>
                                            </span>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                            <hr>
                            <br>
                            <v-row>
                                <v-col>
                                    <v-text-field color="primary" label="Search for a location..." v-model="searchQuery"
                                        variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="1" style="margin-top: -0.6%">
                                    <v-btn justify="center" @click="search" color="success" class="mt-4" text icon>
                                        <v-icon>mdi-magnify</v-icon> </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <div style="height:600px; max-width:100%">
                                        <l-map @click="onMapClick" ref="map" :zoom="zoom" :center="center">
                                            <l-tile-layer :url="url" layer-type="base" name="OpenStreetMap"></l-tile-layer>
                                            <l-marker v-if="marker" :lat-lng="marker"></l-marker>
                                        </l-map>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-file-input v-model="imageModel" @change="onFileSelectedImage"
                                        @click:clear="clearFileImage" prepend-icon="mdi-camera"
                                        label="Upload Image (Optional)" accept="image/png, image/jpeg, image/bmp"
                                        :show-size="1000" variant="outlined"></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row v-if="imageUrl">
                                <v-col>
                                    <img style="max-width: 50%;" :src="imageUrl" />
                                </v-col>
                            </v-row>
                            <v-btn type="submit" color="success" class="mt-4" block>SUBMIT</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { VDataTable } from 'vuetify/labs/VDataTable'
import axios from 'axios'
import "leaflet/dist/leaflet.css"
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet"


export default {
    components: {
        VDataTable,
        LMap,
        LTileLayer,
        LMarker,
    },
    mounted() {
        this.getDataById()
    },
    data() {
        return {
            filterEmail: '',
            memberType: 'pic',
            searchMember: '',
            map: null,
            searchQuery: '',
            zoom: 10,
            center: [40.689247, 74.044502],
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            marker: null,
            isLoading: false,
            idExists: false,
            imageUrl: null,
            pdfUrl: null,
            language: [],
            member: [],
            nameModel: '',
            idModel: null,
            descriptionModel: '',
            memberModel: '',
            imageModel: null,
            nameRules: [
                v => !!v || 'Community Name is required',
            ],
            descriptionRules: [
                v => !!v || 'Description Community is required',
            ],
            headers: [
                {
                    title: 'EMAIL',
                    sortable: true,
                    key: 'email',
                },
                {
                    title: 'USERNAME',
                    sortable: true,
                    key: 'username',
                },
                {
                    title: 'APPROVED',
                    sortable: true,
                    key: 'registered',
                },
                {
                    title: 'TYPE',
                    sortable: true,
                    key: 'memberType',
                },
                { title: "CHANGE MEMBER TYPE", key: 'changeMember', sortable: false },
                { title: "ACTIONS", key: 'actions', sortable: false },
            ],
            listMember: [],
        };
    },
    methods: {
        onMapClick(event) {
            const latlng = event.latlng || event.target._latlng
            if (latlng !== "undifined") {
                this.center = [latlng.lat, latlng.lng]
                this.marker = [latlng.lat, latlng.lng]
            }
        },
        async search() {
            const query = encodeURIComponent(this.searchQuery);
            const url = `https://nominatim.openstreetmap.org/search?q=${query}&format=json`;
            const response = await fetch(url);
            const data = await response.json();
            if (data.length > 0) {
                const latlng = [data[0].lat, data[0].lon];
                this.marker = latlng;
                this.center = latlng;
            } else {
                alert('Location not found.');
            }
        },
        remove(item) {
            this.chips.splice(this.chips.indexOf(item), 1)
        },
        onFileSelectedImage(event) {
            const file = event.target.files[0]
            this.selectedFile = file
            this.imageUrl = file ? URL.createObjectURL(file) : null
        },
        clearFileImage() {
            this.imageModel = null
            this.imageUrl = null
        },
        addMember() {
            if (this.memberModel === "") {
                this.$swal
                    .fire({
                        title: "warning",
                        text: "Can't add, because text field empty",
                        type: "warning",
                        icon: "warning",
                    })
            } else {
                var type
                if (this.memberType === 'pic') {
                    type = 1
                } else {
                    type = 2
                }
                this.$swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Add",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true
                            let id = this.$route.params.id
                            var formData = new FormData();
                            formData.append('id', id)
                            formData.append('member', this.memberModel.id)
                            formData.append('type', type)


                            axios.post(this.$store.state.API_PATH_COMMUNITY + 'add-member', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            }).then((response) => {
                                this.$swal
                                    .fire({
                                        title: "success",
                                        text: response.data.message,
                                        type: "success",
                                        icon: "success",
                                    })
                                    .then(function () {
                                        location.reload();
                                    });
                            }).catch(error => {
                                console.log(error)
                            }).finally(() => (this.isLoading = false))
                        }
                    })
            }
        },
        deleteItem(item) {
            if (this.listMember.filter(member => member.memberType === 'pic').length === 1 && item.memberType === 'pic') {
                this.$swal
                    .fire({
                        title: "warning",
                        text: "Can't remove, because there's only one pic",
                        type: "warning",
                        icon: "warning",
                    })
            } else {
                this.$swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, remove",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true
                            let id = this.$route.params.id
                            axios.delete(this.$store.state.API_PATH_COMMUNITY + 'remove-member', { params: { id: id, member: item.id } }, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            }).then((response) => {
                                this.$swal
                                    .fire({
                                        title: "success",
                                        text: response.data.message,
                                        type: "success",
                                        icon: "success",
                                    })
                                    .then(function () {
                                        location.reload();
                                    });
                            }).catch(error => {
                                console.log(error)
                            }).finally(() => (this.isLoading = false))
                        }
                    })
            }
        },
        async checkId() {
            await axios.get(this.$store.state.API_PATH_COMMUNITY + 'check-community-id', { params: { communityId: this.idModel, type: 2 } }).then((response) => {
                this.idExists = response.data.data
            }).catch(error => {
                console.log(error)
            })
        },
        async getAllUser() {
            await axios.get(this.$store.state.API_PATH_USERS + 'get-all-user').then((response) => {
                this.member = response.data.data.filter(member => !this.listMember.some(listMember => listMember.email === member.email))
            }).catch(error => {
                console.log(error)
            })
        },
        async getDataById() {
            this.isLoading = true
            let id = this.$route.params.id
            await axios.get(this.$store.state.API_PATH_COMMUNITY + 'get-detail-community', { params: { id: id } }).then((response) => {
                this.listMember = response.data.data.member
                this.center = [parseFloat(response.data.data.location.latitude), parseFloat(response.data.data.location.longitude)]
                this.marker = [parseFloat(response.data.data.location.latitude), parseFloat(response.data.data.location.longitude)]
                this.nameModel = response.data.data.communityName
                this.idModel = response.data.data.communityId.replace('@', '')
                this.descriptionModel = response.data.data.descriptionCommunity
                if (response.data.data.imageCommunity) {
                    this.imageUrl = response.data.data.imageCommunity
                }
                this.getAllUser()
                const getStorage = JSON.parse(sessionStorage.getItem('credentials'))
                this.filterEmail = getStorage.email
            }).catch(error => {
                console.log(error)
            }).finally(() => (this.isLoading = false))
        },
        changeMemberType(item) {
            if (this.listMember.filter(member => member.memberType === 'pic').length === 1 && item.memberType === 'pic') {
                this.$swal
                    .fire({
                        title: "warning",
                        text: "Can't change, because there's only one pic",
                        type: "warning",
                        icon: "warning",
                    })
            } else {
                var type;
                if (item.memberType === 'pic') {
                    type = 'pic'
                } else {
                    type = 'member'
                }

                this.$swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Change",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true
                            let id = this.$route.params.id
                            var formData = new FormData();
                            formData.append('id', id)
                            formData.append('idUser', item.id)
                            formData.append('type', type)
                            axios.post(this.$store.state.API_PATH_COMMUNITY + 'set-member-type', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            }).then((response) => {
                                this.$swal
                                    .fire({
                                        title: "success",
                                        text: response.data.message,
                                        type: "success",
                                        icon: "success",
                                    })
                                    .then(function () {
                                        location.reload();
                                    });
                            }).catch(error => {
                                console.log(error)
                            }).finally(() => (this.isLoading = false))
                        }
                    })
            }
        },
        async submitForm(event) {
            const result = await event
            const parse = JSON.parse(JSON.stringify(result))

            if (parse.valid) {
                this.$swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Edit",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true
                            let id = this.$route.params.id
                            var formData = new FormData();
                            formData.append('id', id)
                            formData.append('communityName', this.nameModel)
                            formData.append('communityId', this.idModel)
                            formData.append('descriptionCommunity', this.descriptionModel)

                            if (this.imageModel) {
                                formData.append('imageCommunity', this.imageModel[0])
                            }

                            formData.append('latitude', String(this.marker[0]))
                            formData.append('longitude', String(this.marker[1]))

                            axios.post(this.$store.state.API_PATH_COMMUNITY + 'update-community', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            }).then((response) => {
                                this.$swal
                                    .fire({
                                        title: "success",
                                        text: response.data.message,
                                        type: "success",
                                        icon: "success",
                                    })
                                    .then(function () {
                                        location.reload();
                                    });
                            }).catch(error => {
                                console.log(error)
                            }).finally(() => (this.isLoading = false))
                        }
                    })
            } else {
                this.$swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Check Required Input!",
                })
            }
        },
        approveMember(item) {
            this.$swal
                .fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Approve",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true
                        let communityID = this.$route.params.id
                        var formData = new FormData();
                        formData.append('communityID', communityID)
                        formData.append('userID', item.id)
                        axios.post(this.$store.state.API_PATH_COMMUNITY + 'approve-member', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }).then((response) => {
                            this.$swal
                                .fire({
                                    title: "success",
                                    text: response.data.message,
                                    type: "success",
                                    icon: "success",
                                })
                                .then(function () {
                                    location.reload();
                                });
                        }).catch(error => {
                            console.log(error)
                        }).finally(() => (this.isLoading = false))
                    }
                })
        },
    },
    computed: {
        requiredIdRule() {
            return (value) => !!value || 'Community Id is required';
        },
        idExistsRule() {
            return () => !this.idExists || 'Community Id already exists'
        },
    }
};
</script>
